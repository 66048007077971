import {
    Button,
    Typography,
    Box,
    CardContent,
    CardMedia,
    Card,
    FormControl,
    TextField,
    Modal,
    Select,
    MenuItem,
    FormHelperText,
    InputLabel,
    Breadcrumbs,
    Paper,
    TextareaAutosize,
    FormControlLabel,
    Checkbox,
    FormLabel,
} from "@mui/material";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { IMAGE_UPLOAD } from "../../gql/image";
import RichTextEditor from "react-rte";
import CancelIcon from "@mui/icons-material/Cancel";
import { useMutation } from "@apollo/client";
import imageService from "../../services/image";

import { ALL_ARTICLES, CREATE_ARTICLE } from "../../gql/article";

const imageType = ["image/jpeg", "image/png"];
const videoType = "video/*";
const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
    ],
};

const CreateSerie = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [values, setValues] = useState({});
    const [dates, setDates] = useState();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    const [imagePreview, setImagePreview] = useState("");
    const [imageFile, setImageFile] = useState("");

    const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
    const [textValueMM, setTextValueMM] = useState(
        RichTextEditor.createEmptyValue()
    );

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    //for image upload
    const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
        onError: (error) => {
            alert("Error on Server");
            console.log("error ", error);
        },
        onCompleted: (result) => {
            return result;
        },
    });

    const chooseImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            let image = e.target.files[0];
            if (!imageType.includes(image.type)) {
                setError({
                    ...error,
                    image_url: "Please Select image (png,jpeg)",
                });
                return;
            }
            if (image.size > 10485760) {
                setError({
                    ...error,
                    image_url: "Image size must be smaller than 10MB",
                });
                return;
            }
            setImageFile(image);
            setImagePreview(URL.createObjectURL(image));
            setValues({ ...values, image_url: URL.createObjectURL(image) });
        }
    };

    const [add_video] = useMutation(CREATE_ARTICLE, {
        onError: (err) => {
            alert("Error on server");
            setLoading(false);
        },
        onCompleted: (result) => {
            setLoading(false);
            setTextValue(RichTextEditor.createEmptyValue());
            setValues({});
            alert("New Article has been added");
            navigate(-1);
        },
        refetchQueries: [ALL_ARTICLES],
    });

    const onChange = (value) => {
        setTextValue(value);
        setValues({ ...values, description_en: value.toString("html") });
    };
    const onChangeMM = (value) => {
        setTextValueMM(value);
        setValues({ ...values, description_mm: value.toString("html") });
    };

    const handleCreate = async () => {
        setLoading(true);
        let isErrorExit = false;
        let errorObject = {};
        if (!values.name_en) {
            isErrorExit = true;
            errorObject.name_en = "Article name Eng is required";
        }
        if (!values.name_mm) {
            isErrorExit = true;
            errorObject.name_mm = "Article name MM is required";
        }
        if (!values.image_url) {
            isErrorExit = true;
            errorObject.image_url = "Image url is required";
        }

        if (!values.description_en) {
            isErrorExit = true;
            errorObject.description_en = "Description Eng is required";
        }
        if (!values.description_mm) {
            isErrorExit = true;
            errorObject.description_mm = "Description MM is required";
        }
        if (!values.duration_time) {
            isErrorExit = true;
            errorObject.duration_time = "duration_time  is required";
        }
        if (isErrorExit) {
            console.log("err obj", errorObject);
            setError(errorObject);
            setLoading(false);
            return;
        }

        try {
            const uploadUrl = await getImageUrl({
                variables: { contentType: "image/*" },
            });
            await imageService.uploadImage(
                uploadUrl.data.getImageUploadUrl.imageUploadUrl,
                imageFile
            );
            await add_video({
                variables: {
                    ...values,
                    duration_time: Number(values.duration_time),
                    image_url: `https://axra.sgp1.digitaloceanspaces.com/Mula/${uploadUrl.data.getImageUploadUrl.imageName}`,
                },
            });
        } catch (error) {
            console.log("Error ", error);
        }
    };
    console.log("values..........", values);
    return (
        <>
            <Card>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "1rem",
                    }}
                >
                    {/* dashboard */}
                    <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* <Link to="/" className="dashboard"> */}
                            <Typography variant="h6">Mula Dashboard (Article)</Typography>
                        </Breadcrumbs>
                        <Typography>Main / Article</Typography>
                    </div>
                    <Button color="warning" onClick={() => navigate(-1)}>
                        <CancelIcon sx={{ width: "50px", height: "50px" }} />
                    </Button>
                </div>
                <CardContent sx={{ p: 3 }} elevation={4}>
                    <Box display="flex" gap="2rem" justifyContent="center">
                        <Box>
                            <Box className="image">
                                <CardMedia
                                    component="img"
                                    height="200px"
                                    image={imagePreview}
                                />
                            </Box>
                            <Box display="flex" justifyContent="center" mb="1rem">
                                {/* image */}
                                <FormControl
                                    sx={{
                                        maxWidth: "70%",
                                    }}
                                //   className="photoCamera"
                                >
                                    <Typography
                                        sx={{
                                            mb: 1,
                                            fontSize: "12px",
                                            textAlign: "center",
                                        }}
                                    >
                                        Rendered size must be 1920 * 1080 px and Aspect ratio must
                                        be 16:9Cli
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        size="large"
                                    // sx={{ py: "0.5rem" }}
                                    >
                                        <CloudUploadSharpIcon />
                                        <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                                        <input
                                            hidden
                                            onChange={chooseImage}
                                            accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                                            type="file"
                                            error={error["image_url"]}
                                        />
                                    </Button>
                                    <FormHelperText error>{error["image_url"]}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        className="grid_container"
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            // gridTemplateColumns: repeat(auto - fit, minmax("200px, 1fr")),
                            px: "0.5rem",
                            rowGap: "1rem",
                            columnGap: "5rem",
                            mb: "2rem",
                        }}
                    >
                        {/* article Name Eng */}
                        <FormControl>
                            <FormLabel sx={{ fontWeight: "bold" }}>Article Name</FormLabel>
                            <TextField
                                variant="outlined"
                                InputProps={{ sx: { height: 50 } }}
                                id="name_en"
                                placeholder="Enter Article Name (Eng)"
                                value={values.name_en}
                                onChange={handleChange("name_en")}
                                error={error.name_en ? true : false}
                                helperText={error.name_en}
                            />
                        </FormControl>

                        {/* Article Name MM */}
                        <FormControl>
                            <FormLabel sx={{ fontWeight: "bold" }}>Article Name MM</FormLabel>
                            <TextField
                                variant="outlined"
                                InputProps={{ sx: { height: 50 } }}
                                id="name_mm"
                                placeholder="Enter Article Name (MM)"
                                value={values.name_mm}
                                onChange={handleChange("name_mm")}
                                error={error.name_mm ? true : false}
                                helperText={error.name_mm}
                            />
                        </FormControl>

                        {/* duration in time */}
                        <FormControl>
                            <FormLabel sx={{ fontWeight: "bold" }}>
                                Duration In Time
                            </FormLabel>
                            <TextField
                                type="number"
                                variant="outlined"
                                InputProps={{ sx: { height: 50 } }}
                                id="duration_time"
                                placeholder="Enter Duration"
                                value={values.duration_time}
                                onChange={handleChange("duration_time")}
                                error={error.duration_time ? true : false}
                                helperText={error.duration_time}
                            />
                        </FormControl>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr"
                        rowGap="1rem"
                        columnGap="5rem"
                        px="0.5rem"
                    >
                        {/* description Eng */}
                        <Box>
                            <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                                Description (Eng)
                            </InputLabel>
                            <RichTextEditor
                                className="description-text"
                                onChange={onChange}
                                value={textValue}
                                toolbarConfig={toolbarConfig}
                            />
                            {error.event_description && (
                                <FormHelperText error>{error.event_description}</FormHelperText>
                            )}
                        </Box>

                        {/* description_mm */}
                        <Box>
                            <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                                Description (MM)
                            </InputLabel>
                            <RichTextEditor
                                className="description-text"
                                onChange={onChangeMM}
                                value={textValueMM}
                                toolbarConfig={toolbarConfig}
                            />
                            {error.event_description_mm && (
                                <FormHelperText error>
                                    {" "}
                                    {error.event_description_mm}
                                </FormHelperText>
                            )}
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "end", m: "2rem" }}>
                        <LoadingButton
                            variant="contained"
                            onClick={handleCreate}
                            loading={loading}
                        >
                            Create
                        </LoadingButton>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default CreateSerie;
