import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import * as XLSX from "xlsx-js-style";

import DirectionsIcon from "@mui/icons-material/Directions";
import "../../style/App.css";
import {
  Box,
  Breadcrumbs,
  Button,
  TablePagination,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  styled,
  TableRow,
  TableCell,
  Avatar,
} from "@mui/material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ALL_USER, ALL_USERS } from "../../gql/user";
import SideBarContext from "../../context/SideBarContext";

const Users = () => {
  const { setNav } = useContext(SideBarContext);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [user, setUser] = useState("");
  const [users, setUsers] = useState("");
  const [loadUser, resultUser] = useLazyQuery(ALL_USERS);

  useEffect(() => {
    loadUser({
      variables: { limit: rowsPerPage, offset: offset, search: `%${search}%` },
      fetchPolicy: "network-only",
    });
  }, [loadUser, rowsPerPage, offset, search]);

  useEffect(() => {
    if (resultUser.data) {
      setUser(resultUser.data.users);
      setCount(Number(resultUser.data?.users_aggregate.aggregate.count));
    }
  }, [resultUser]);

  console.log("resute user", resultUser);

  //for export
  const { loading, error, data } = useQuery(ALL_USER, {
    variables: { search: `%${search}%` },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && data) {
      setUsers(data.users);
    }
  }, [loading, data]);

  if (!user) {
    return "no user";
  }

  const idsFromDatabase = Array.isArray(user) && user.map((u) => u.id);
  console.log("ids from database", idsFromDatabase);
  // const orderedIds = idsFromDatabase.sort((a, b) => a - b);

  // const newSequentialIds = orderedIds.map((id, index) => index + 1);

  // setUser({ ...user, id: newSequentialIds });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchValue);
    if (searchValue === "") {
      loadUser({
        variables: {
          limit: rowsPerPage,
          offset: offset,
          search: `%${search}%`,
        },
        fetchPolicy: "network-only",
      });
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const exportAllData = () => {
    let data = [];

    const date = new Date();
    const fileName = date.toISOString().slice(0, 10);
    const time = formatTime(date);

    data.push(["MULA ART GALLERY"]);
    data.push([
      "E1-12,The Secretariat Yangon,Thein Phyu Road, Botahtaung Township, Yangon, Myanmar",
    ]);
    data.push([`Date and Time: ${fileName} - ${time}`]);
    data.push([""]);

    const columns = [
      "Sr.No",
      "ID",
      "Full Name",
      "Phone Number",
      "Email",
      "Address",
      "Gender",
    ];

    data.push(columns);

    for (let index = 0; index < users.length; index++) {
      if (users[index] !== undefined) {
        const row = [
          index + 1,
          users[index].id ? users[index].id : "-",
          users[index].fullname ? users[index].fullname : "-",
          users[index].phone ? users[index].phone : "-",
          users[index].email ? users[index].email : "-",
          users[index].address ? users[index].address : "-",
          users[index].gender ? users[index].gender : "-",
        ];

        data.push(row);
      }
    }

    if (users.length > 0) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);

      // Resize column width
      ws["!cols"] = [
        { width: 10 }, // Width for column A
        { width: 10 }, // Width for column B
        { width: 20 }, // Width for column C
        { width: 20 }, // Width for column D
        { width: 25 }, // Width for column E
        { width: 30 }, // Width for column F
        { width: 15 }, // Width for column G
      ];

      // Adding styles for "Welcome" row and header row
      // ws["A1"].s = { font: { bold: true } }; // Style for "Welcome" row
      for (let col = 0; col < columns.length; col++) {
        const headerCell = XLSX.utils.encode_cell({ r: 4, c: col });
        ws[headerCell].s = {
          font: { bold: true },
          alignment: { horizontal: "center" },
        }; // Style for header row
      }

      // Merging cells A1 to G1 and centering the text
      ws["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: columns.length - 1 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: columns.length - 1 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: columns.length - 1 } },
        { s: { r: 3, c: 0 }, e: { r: 3, c: columns.length - 1 } },
      ];

      // Apply bold and center styles for the specific rows
      for (let i = 0; i < 3; i++) {
        const cellAddress = XLSX.utils.encode_cell({ r: i, c: 0 });
        ws[cellAddress].s = {
          font: { bold: true },
          alignment: { horizontal: "center" },
        };
      }

      for (let i = 5; i < users.length + 5; i++) {
        for (let c = 0; c < columns.length; c++) {
          const cellAddress = XLSX.utils.encode_cell({ r: i, c: c });
          ws[cellAddress].s = { alignment: { horizontal: "center" } };
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, "Receipt Data");
      XLSX.writeFile(wb, `UserList_${fileName}.xlsx`);
    } else {
      alert("No Data to export!");
    }
  };

  if (!user) {
    return (
      <div className="loading">
        <em>Loading...</em>
      </div>
    );
  }
  console.log("user ", user);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
          "@media (max-width: 480px)": {
            display: "grid",
            gap: "1rem",
            padding: "0",
          },
        }}
      >
        {/* dashboard */}
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard" className="dashboard">
              Dashboard
            </Link>
            <span>Users</span>
          </Breadcrumbs>
        </div>
        {/* search */}
        <div>
          <form onSubmit={handleSearch}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "350px",
                // "@media (max-width: 320px)": {
                //   width: "auto",
                // },
              }}
            >
              {/* Search Box */}
              <InputBase
                id="search-by-phone"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search By Fullname or Phone"
                type="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                //   color="warning"
                sx={{ p: "10px" }}
                aria-label="directions"
                value={search}
                onClick={handleSearch}
                type="submit"
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </form>
        </div>
        {/* add */}

        <Button
          variant="contained"
          sx={{
            px: 3,
            py: 1,
          }}
          color="secondary"
          onClick={exportAllData}
        >
          Export
        </Button>

        {/* <Button
          variant="contained"
          sx={{
            px: 3,
            py: 1,
          }}
          color="secondary"
          onClick={() => navigate("/create_user")}
        >
          Add
        </Button> */}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            Width: "100px",
            border: "1px groove rgba(0,0,0,0.2)",
          }}
        >
          <Table stickyHeader aria-label="sticky table , responsive table">
            <TableHead>
              <StyledTableRow>
                <TableCell
                  style={{
                    minWidth: 70,
                    fontWeight: "bold",
                  }}
                >
                  ID
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Profile Image
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Name
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Phone
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Created At
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Updated At
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </StyledTableRow>
            </TableHead>

            {user.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Users Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {user.map((row, index) => (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {new Date() - new Date(row.created_at) <=
                    2 * 24 * 60 * 60 * 1000 ? (
                      <TableCell sx={{ color: "red" }}>{index + 1}</TableCell>
                    ) : (
                      <TableCell>{index + 1}</TableCell>
                    )}

                    <TableCell>
                      <Avatar
                        width="52px"
                        height="52px"
                        src={row.profile_image_url}
                      ></Avatar>
                    </TableCell>
                    {new Date() - new Date(row.created_at) <=
                    2 * 24 * 60 * 60 * 1000 ? (
                      <TableCell sx={{ color: "red" }}>
                        {row.fullname}
                      </TableCell>
                    ) : (
                      <TableCell>{row.fullname}</TableCell>
                    )}
                    {new Date() - new Date(row.created_at) <=
                    2 * 24 * 60 * 60 * 1000 ? (
                      <TableCell sx={{ color: "red" }}>{row.phone}</TableCell>
                    ) : (
                      <TableCell>{row.phone}</TableCell>
                    )}
                    {new Date() - new Date(row.created_at) <=
                    2 * 24 * 60 * 60 * 1000 ? (
                      <TableCell sx={{ color: "red" }}>
                        {row.created_at.slice(0, 10)}
                      </TableCell>
                    ) : (
                      <TableCell>{row.created_at.slice(0, 10)}</TableCell>
                    )}
                    {new Date() - new Date(row.created_at) <=
                    2 * 24 * 60 * 60 * 1000 ? (
                      <TableCell sx={{ color: "red" }}>
                        {row.updated_at.slice(0, 10)}
                      </TableCell>
                    ) : (
                      <TableCell>{row.updated_at.slice(0, 10)}</TableCell>
                    )}

                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        sx={{ color: "white", p: 1, mr: 1 }}
                        onClick={() => navigate(`/user/${row.id}`)}
                      >
                        Detail
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ color: "black" }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
};

export default Users;

function formatTime(dateTimeString) {
  const date = new Date(dateTimeString);
  return date.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
}
