import {
  Button,
  Typography,
  Box,
  CardContent,
  CardMedia,
  Card,
  FormControl,
  TextField,
  FormHelperText,
  InputLabel,
  Breadcrumbs,
  FormLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { DELETE_IMAGE, IMAGE_UPLOAD } from "../../gql/image";
import RichTextEditor from "react-rte";
import CancelIcon from "@mui/icons-material/Cancel";

import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import imageService from "../../services/image";
import {
  ALL_VIDEOS,
  ONE_VIDEO,
  UPDATE_VIDEO_UPLOAD,
  VIDEO_CAT,
  VIDEO_CREATOR,
} from "../../gql/videoUpload";

const imageType = ["image/jpeg", "image/png"];
const videoType = "video/*";
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const UpdateVideo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [dates, setDates] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [isTraditional, setIsTraditional] = useState(true);
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [isImageChange, setIsImageChange] = useState(false);
  const [oldImageName, setOldImageName] = useState();
  const [imageFileUrl, setImageFileUrl] = useState();

  const [videoPreview, setVideoPreview] = useState("");
  const [videoFile, setVideoFile] = useState("");
  const [isVideoChange, setIsVideoChange] = useState(false);
  const [oldVideoName, setOldVideoName] = useState();
  const [videoFileUrl, setVideoFileUrl] = useState();
  const [duration, setDuration] = useState();
  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  const [textValueMM, setTextValueMM] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [loadVideo, resultVideo] = useLazyQuery(ONE_VIDEO);
  const { data: videoCat } = useQuery(VIDEO_CAT, {
    variables: {
      is_traditional: isTraditional,
    },
    fetchPolicy: "network-only",
  });
  const { data: videoCreator } = useQuery(VIDEO_CREATOR, {
    variables: {
      is_traditional: isTraditional,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    loadVideo({ variables: { id: id } });
  }, [loadVideo]);

  useEffect(() => {
    if (resultVideo.data) {
      setValues({
        id: resultVideo?.data.videos_by_pk.id ?? "",
        thumbnail_image_url:
          resultVideo?.data.videos_by_pk.thumbnail_image_url ?? "",
        video_url: resultVideo?.data.videos_by_pk.video_url ?? "",
        video_name: resultVideo?.data.videos_by_pk.video_name ?? "",
        is_traditional: resultVideo?.data.videos_by_pk?.is_traditional ?? "",
        video_name_mm: resultVideo?.data.videos_by_pk.video_name_mm ?? "",
        description_en: resultVideo?.data.videos_by_pk.description_en ?? "",
        description_mm: resultVideo?.data.videos_by_pk.description_mm ?? "",
        duration_minute: resultVideo?.data.videos_by_pk.duration_minute ?? "",
        fk_video_category_id:
          resultVideo?.data.videos_by_pk?.fk_video_category_id ?? "",
        fk_video_creator_id:
          resultVideo?.data.videos_by_pk?.fk_video_creator_id ?? "",
      });
      setIsTraditional(resultVideo?.data.videos_by_pk?.is_traditional ?? "");
      setDuration(resultVideo?.data.videos_by_pk.duration_minute ?? 0);
      setTextValue(
        RichTextEditor.createValueFromString(
          resultVideo.data.videos_by_pk.description_en,
          "html"
        )
      );
      setTextValueMM(
        RichTextEditor.createValueFromString(
          resultVideo.data.videos_by_pk.description_mm,
          "html"
        )
      );
      setImagePreview(resultVideo?.data.videos_by_pk.thumbnail_image_url);
      let image = resultVideo.data.videos_by_pk.thumbnail_image_url;
      setOldImageName(
        image.substring(image.lastIndexOf("/") + 1, image.lenght)
      );
      setVideoPreview(resultVideo?.data.videos_by_pk.video_url);
      let video = resultVideo.data.videos_by_pk.video_url;
      setOldVideoName(
        video.substring(video.lastIndexOf("/") + 1, video.lenght)
      );
    }
  }, [resultVideo]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  //for image upload
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      // alert("Error on Server");
      console.log("error ", error);
    },
    onCompleted: (result) => {
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsImageChange(true);
      setValues({
        ...values,
        thumbnail_image_url: `https://axra.sgp1.digitaloceanspaces.com/Mula/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const chooseImage = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!imageType.includes(image.type)) {
        setError({
          ...error,
          thumbnail_image_url: "Please Select image (png,jpeg)",
        });
        return;
      }
      if (image.size > 10485760) {
        setError({
          ...error,
          thumbnail_image_url: "Image size must be smaller than 10MB",
        });
        return;
      }

      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  const [delete_image] = useMutation(DELETE_IMAGE, {
    onError: (err) => {
      // alert("Error on Server");
      setLoading(false);
    },
  });

  //for video upload
  const [getVideoUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      alert("Error on Server");
      console.log("error ", error);
    },
    onCompleted: (result) => {
      setVideoFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsVideoChange(true);
      setValues({
        ...values,
        video_url: `https://axra.sgp1.digitaloceanspaces.com/Mula/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const chooseVideo = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let videofile = e.target.files[0];
      const video = document.createElement("video");
      video.src = window.URL.createObjectURL(videofile);

      video.onloadedmetadata = () => {
        if (video.videoHeight !== 720 && video.videoWidth !== 1280) {
          setError({
            ...error,
            video_url: "Video resolution must be 1280*720 ",
          });
          return;
        }
        const durationInMinutes = Math.round(video.duration);
        setDuration(durationInMinutes);
      };
      console.log("video file ", videofile);
      setVideoFile(videofile);
      setVideoPreview(URL.createObjectURL(videofile));
      getVideoUrl({ variables: { contentType: "video/mp4" } });
      // document.body.appendChild(video);
    }
  };

  const [update_video] = useMutation(UPDATE_VIDEO_UPLOAD, {
    onError: (err) => {
      alert("Error on server");
      setLoading(false);
    },
    onCompleted: (result) => {
      setLoading(false);
      setTextValue(RichTextEditor.createEmptyValue());
      setValues({});
      alert("New Video has been updated");
      navigate(-1);
    },
    refetchQueries: [ONE_VIDEO],
  });

  const onChange = (value) => {
    setTextValue(value);
    setValues({ ...values, description_en: value.toString("html") });
  };
  const onChangeMM = (value) => {
    setTextValueMM(value);
    setValues({ ...values, description_mm: value.toString("html") });
  };
  const handleUpdate = async () => {
    setLoading(true);
    let isErrorExit = false;
    let errorObject = {};
    if (!values.video_name) {
      isErrorExit = true;
      errorObject.video_name = "Video name Eng is required";
    }
    if (!values.video_name_mm) {
      isErrorExit = true;
      errorObject.video_name_mm = "Video name MM is required";
    }
    if (!values.thumbnail_image_url) {
      isErrorExit = true;
      errorObject.thumbnail_image_url = "Image url is required";
    }
    if (!values.video_url) {
      isErrorExit = true;
      errorObject.video_url = "video_url is required";
    }
    if (!values.description_en) {
      isErrorExit = true;
      errorObject.description_en = "Description Eng is required";
    }
    if (!values.description_mm) {
      isErrorExit = true;
      errorObject.description_mm = "Description MM is required";
    }
    if (isErrorExit) {
      console.log("err obj", errorObject);
      setError(errorObject);
      setLoading(false);
      return;
    }

    try {
      // const uploadUrl = await getImageUrl({
      //   variables: { contentType: "image/*" },
      // });
      // await imageService.uploadImage(
      //   uploadUrl.data.getImageUploadUrl.imageUploadUrl,
      //   imageFile
      // );
      // //video upload
      // const videoUrl = await getVideoUrl({
      //   variables: { contentType: "mp4/*" },
      // });
      // await imageService.uploadImage(
      //   videoUrl.data.getImageUploadUrl.imageUploadUrl,
      //   videoFile
      // );
      if (isImageChange) {
        await imageService.uploadImage(imageFileUrl, imageFile);
        await delete_image({ variables: { image_name: oldImageName } });
      }
      if (isVideoChange) {
        await imageService.uploadImage(videoFileUrl, videoFile);
        await delete_image({ variables: { image_name: oldVideoName } });
      }

      await update_video({
        variables: {
          ...values,
          is_traditional: isTraditional,
          duration_minute: duration,
        },
      });
    } catch (error) {
      console.log("Error ", error);
    }
  };

  return (
    <>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          {/* dashboard */}
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              {/* <Link to="/" className="dashboard"> */}
              <Typography variant="h6">
                Mula Dashboard (Video Upload)
              </Typography>
            </Breadcrumbs>
            <Typography>Main / Video Upload</Typography>
          </div>
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </div>

        <CardContent sx={{ p: 3 }} elevation={4}>
          <Box display="flex" gap="2rem" justifyContent="center">
            <Box>
              <Box className="image">
                <CardMedia
                  component="img"
                  height="200px"
                  image={imagePreview}
                  // sx={{ my: 2 }}
                />
              </Box>
              <Box display="flex" justifyContent="center" mb="1rem">
                {/* image */}
                <FormControl
                  sx={{
                    maxWidth: "70%",
                  }}
                  //   className="photoCamera"
                >
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    Rendered size must be 1920 * 1080 px and Aspect ratio must
                    be 16:9Cli
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    size="large"
                    // sx={{ py: "0.5rem" }}
                  >
                    {/* <PhotoCamera /> */}
                    <CloudUploadSharpIcon />
                    <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                    <input
                      id="image"
                      hidden
                      onChange={chooseImage}
                      accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                      type="file"
                      error={error["thumbnail_image_url"]}
                    />
                  </Button>
                  <FormHelperText error>
                    {error["thumbnail_image_url"]}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Box>
            {/* Video Upload */}
            <Box>
              <Box className="image">
                {values?.video_url && (
                  <video controls width="auto" height="200px">
                    <source
                      src={videoPreview ? videoPreview : ""}
                      type="video/mp4"
                    />
                  </video>
                )}
              </Box>
              <Box display="flex" justifyContent="space-around" mb="1rem">
                {/* image */}
                <FormControl
                  sx={{
                    maxWidth: "70%",
                  }}
                  //   className="photoCamera"
                >
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    Rendered size must be 1920 * 1080 px and Aspect ratio must
                    be 16:9Cli
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    size="large"
                    // sx={{ py: "0.5rem" }}
                  >
                    {/* <PhotoCamera /> */}
                    <CloudUploadSharpIcon />
                    <Typography sx={{ ml: 1 }}>Upload Video</Typography>
                    <input
                      hidden
                      type="file"
                      onChange={chooseVideo}
                      accept="video/webm,video/mp4"
                      error={error["video_url"]}
                    />
                  </Button>
                  <FormHelperText error>{error["video_url"]}</FormHelperText>
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box sx={{ my: "1rem", display: "flex", justifyContent: "center" }}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Choose Traditional or Digital
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isTraditional}
                onChange={(e) => setIsTraditional(e.target.value)}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Traditional"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Digital"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            className="grid_container"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              // gridTemplateColumns: repeat(auto - fit, minmax("200px, 1fr")),
              px: "0.5rem",
              rowGap: "1rem",
              columnGap: "5rem",
              mb: "2rem",
            }}
          >
            {/* video Category*/}

            {values?.fk_video_category_id && (
              <FormControl>
                <FormLabel style={{ fontWeight: "bold" }}>
                  Video Category
                </FormLabel>
                {/* <InputLabel id="sub_type">Ownership</InputLabel> */}
                <Select
                  style={{ height: "50px" }}
                  variant="outlined"
                  defaultValue=""
                  value={values.fk_video_category_id}
                  onChange={handleChange("fk_video_category_id")}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled={true} hidden>
                    Enter Video Category
                  </MenuItem>

                  {Array.isArray(videoCat?.video_category)
                    ? videoCat?.video_category.map((video) => (
                        <MenuItem key={video.id} value={video.id}>
                          {video.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {error.fk_video_category_id && (
                  <FormHelperText error>
                    {error.fk_video_category_id}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            {/* video Creator*/}

            {values?.fk_video_creator_id && (
              <FormControl>
                <FormLabel style={{ fontWeight: "bold" }}>
                  Video Creator
                </FormLabel>
                {/* <InputLabel id="sub_type">Ownership</InputLabel> */}
                <Select
                  style={{ height: "50px" }}
                  variant="outlined"
                  defaultValue=""
                  value={values.fk_video_creator_id}
                  onChange={handleChange("fk_video_creator_id")}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled={true} hidden>
                    Enter Video Creator
                  </MenuItem>

                  {Array.isArray(videoCreator?.video_creator)
                    ? videoCreator?.video_creator.map((video) => (
                        <MenuItem key={video.id} value={video.id}>
                          {video.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {error.fk_video_creator_id && (
                  <FormHelperText error>
                    {error.fk_video_creator_id}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            {/* Video Name Eng */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Video Name</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="video_name"
                placeholder="Enter Video Name (Eng)"
                value={values.video_name}
                onChange={handleChange("video_name")}
                error={error.video_name ? true : false}
                helperText={error.video_name}
              />
            </FormControl>

            {/* Video Name MM */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Video Name MM</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="event_name"
                placeholder="Enter Video Name (MM)"
                value={values.video_name_mm}
                onChange={handleChange("video_name_mm")}
                error={error.video_name_mm ? true : false}
                helperText={error.video_name_mm}
              />
            </FormControl>

            {/* duration in minute */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>
                Duration In Minute
              </FormLabel>
              <TextField
                type="number"
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="event_name"
                placeholder="Enter Video Name (MM)"
                value={duration}
              />
            </FormControl>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            rowGap="1rem"
            columnGap="5rem"
            px="0.5rem"
          >
            {/* description Eng */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description (Eng)
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChange}
                value={textValue}
                toolbarConfig={toolbarConfig}
              />
              {error.event_description && (
                <FormHelperText error>
                  {" "}
                  {error.event_description}
                </FormHelperText>
              )}
            </Box>

            {/* description_mm */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description (MM)
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChangeMM}
                value={textValueMM}
                toolbarConfig={toolbarConfig}
              />
              {error.event_description_mm && (
                <FormHelperText error>
                  {" "}
                  {error.event_description_mm}
                </FormHelperText>
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end", m: "2rem" }}>
            <LoadingButton
              variant="contained"
              onClick={handleUpdate}
              loading={loading}
            >
              Update
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default UpdateVideo;
