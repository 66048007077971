import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import DirectionsIcon from "@mui/icons-material/Directions";
import "../../style/App.css";
import {
  Box,
  Breadcrumbs,
  Button,
  TablePagination,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableBody,
  styled,
  TableCell,
  TableRow,
  Modal,
  Avatar,
} from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
// import { USERS } from "../../gql/users";
import SideBarContext from "../../context/SideBarContext";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ALL_VIDEO_CATS, DELETE_VIDEO_CAT } from "../../gql/videoCategory";
import {
  ALL_VIDEO_CREATOR,
  DELETE_VIDEO_CREATOR,
} from "../../gql/videoCreator";

const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  borderRadius: 1,
  p: 4,
};

const VideoCategorys = () => {
  const { setNav } = useContext(SideBarContext);
  const navigate = useNavigate();
  const [delOpen, setDelOpen] = useState(false);
  const [videoData, setVideoData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const [videoCreator, setVideoCreator] = useState();

  const [loadCreator, resultCreator] = useLazyQuery(ALL_VIDEO_CREATOR);

  useEffect(() => {
    loadCreator({
      variables: { search: `%${search}%` },
      fetchPolicy: "network-only",
    });
  }, [loadCreator, search]);

  useEffect(() => {
    if (resultCreator.data) {
      setVideoCreator(resultCreator.data.video_creator);
    }
  }, [resultCreator]);
  console.log("video creator ", videoCreator);
  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchValue);
    if (searchValue === "") {
      loadCreator({
        variables: {
          search: `%${search}%`,
        },
        fetchPolicy: "network-only",
      });
    }
  };

  const [delete_video_cat] = useMutation(DELETE_VIDEO_CREATOR, {
    onError: (err) => {
      console.log("Delete Error ", err);
      alert("Delete Error");
    },
    onCompleted: (data) => {
      alert("Delete Successfull");
    },
    refetchQueries: [ALL_VIDEO_CREATOR],
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      // backgroundColor: "#f1f3f5",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handleRemoveOpen = (data) => {
    setDelOpen(true);
    setVideoData(data);
  };
  const handleRemoveClose = () => setDelOpen(false);
  const handleRemove = async (data) => {
    await delete_video_cat({ variables: { id: videoData?.id } });
    setDelOpen(false);
  };

  if (!videoCreator) {
    return;
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        {/* dashboard */}
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard" className="dashboard">
              Dashboard
            </Link>
            <span>Video Creator</span>
          </Breadcrumbs>
        </div>

        {/* search */}
        <div>
          <form onSubmit={handleSearch}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 350,
              }}
            >
              {/* Search Box */}
              <InputBase
                id="search-by-name"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search By Creator Name"
                type="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                //   color="warning"
                sx={{ p: "10px" }}
                aria-label="directions"
                type="submit"
                value={search}
                onClick={handleSearch}
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </form>
        </div>

        {/* add */}
        <Button
          variant="contained"
          sx={{
            px: 3,
            py: 1,
          }}
          color="secondary"
          onClick={() => navigate("/create_video_creator")}
        >
          Add
        </Button>
      </div>

      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            // maxHeight: "70vh",
            Width: "100px",
            border: "1px groove rgba(0,0,0,0.2)",
          }}
        >
          <Table stickyHeader aria-label="sticky table , responsive table">
            <TableHead>
              <StyledTableRow>
                <TableCell
                  style={{
                    minWidth: 100,
                    fontWeight: "bold",
                  }}
                >
                  ID
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Image
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Video Creator Name
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Creator Name MM
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Traditional or Digital
                </TableCell>
                <TableCell style={{ minWidth: 100, fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </StyledTableRow>
            </TableHead>
            {videoCreator?.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {videoCreator &&
                  videoCreator?.map((row, index) => (
                    <StyledTableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <Avatar
                          width="52px"
                          height="52px"
                          src={row.image_url}
                        ></Avatar>
                      </TableCell>
                      <TableCell>{row.name}</TableCell>

                      <TableCell>{row?.name_mm}</TableCell>
                      <TableCell>
                        {row.is_traditional === true
                          ? "Traditional"
                          : "Digital"}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="error"
                          onClick={() => handleRemoveOpen(row)}
                        >
                          <DeleteIcon />
                        </Button>
                        <Button
                          color="warning"
                          onClick={() =>
                            navigate(`edit_video_creator/${row.id}`)
                          }
                        >
                          <ModeEditIcon />
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
      <Modal
        keepMounted
        open={delOpen}
        onClose={handleRemoveClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleRemoveClose}>
              Cancel
            </Button>
            <Button onClick={handleRemove}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default VideoCategorys;
