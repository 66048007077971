import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {UPDATE_USER, USER} from "../../gql/user";
import {
    Button,
    Typography,
    Breadcrumbs,
    Box,
    CardContent,
    Card,
    FormControl,
    TextField,
    FormLabel,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { USER_ID } from "../../gql/artist";


const UpdateUserPhone = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [values, setValues] = useState({});
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    // const { data } = useQuery(USER);

    const [loadUserId, getUserData] = useLazyQuery(USER);
    console.log("user id", id)
    useEffect(() => {
        loadUserId({ variables: { id: id } });
    }, [loadUserId]);

    useEffect(() => {
        if (getUserData.data) {
            console.log("get attribute data", getUserData.data);
            setValues({
                id: getUserData.data.users_by_pk.id,
                phone: getUserData.data.users_by_pk.phone ?? "",
            });
        }
    }, [getUserData]);

    console.log("values", values);

    const [update_user] = useMutation(UPDATE_USER, {
        onError: (err) => {
            alert("Error on Server");
            setLoading(false);
        },
        onCompleted: (data) => {
            setLoading(false);

            setValues({});
            alert("User phone had been updated");
            navigate(`/user`);
        },
        refetchQueries: [USER_ID],
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleUpdate = async () => {
        setLoading(true);
        try {
            await update_user({
                variables: {
                    ...values,
                },
            });
        } catch (error) {
            console.log("Error ", error);
        }
    };

    // if (!data) {
    //     return;
    // }
    if (!getUserData) {
        return;
    }
    return (
        <>
            <Card>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "1rem",
                    }}
                >
                    {/* dashboard */}
                    <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* <Link to="/" className="dashboard"> */}
                            <Typography variant="h6">Mula Dashboard (User)</Typography>

                            {/* </Link> */}
                            {/* <span>ArtWork</span> */}
                        </Breadcrumbs>
                        <Typography>Main / User</Typography>
                    </div>
                    <Button color="warning" onClick={() => navigate(-1)}>
                        <CancelIcon sx={{ width: "50px", height: "50px" }} />
                    </Button>
                </div>
                <CardContent sx={{ p: 3 }} elevation={4}>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr ",
                            gap: 3,
                            px: "1rem",
                        }}
                    >
                        {/* phone number */}
                        <FormControl>
                            <FormLabel style={{ fontWeight: "bold" }}>
                                User Phone
                            </FormLabel>
                            <TextField
                                InputProps={{ sx: { height: 50 } }}
                                variant="outlined"
                                id="user_phone"
                                placeholder="Enter Value"
                                value={values.phone}
                                onChange={handleChange("phone")}
                                error={error.phone ? true : false}
                                helperText={error.phone}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", m: "2rem" }}>
                        <LoadingButton
                            variant="contained"
                            onClick={handleUpdate}
                            loading={loading}
                        >
                            Update
                        </LoadingButton>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};
export default UpdateUserPhone;
