import {
  Button,
  Typography,
  Box,
  CardContent,
  CardMedia,
  Card,
  FormControl,
  TextField,
  FormHelperText,
  InputLabel,
  Breadcrumbs,
  FormLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { DELETE_IMAGE, IMAGE_UPLOAD } from "../../gql/image";
import RichTextEditor from "react-rte";

import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import imageService from "../../services/image";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  ALL_ARTICLES,
  ARTICLE_CAT,
  ONE_ARTICLE,
  UPDATE_ARTICLE,
} from "../../gql/article";

const imageType = ["image/jpeg", "image/png"];
const videoType = "video/*";
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const UpdateArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [isTraditional, setIsTraditional] = useState(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [isImageChange, setIsImageChange] = useState(false);
  const [oldImageName, setOldImageName] = useState("");

  const [imagePreview1, setImagePreview1] = useState("");
  const [imageFile1, setImageFile1] = useState("");
  const [imageFileUrl1, setImageFileUrl1] = useState("");
  const [isImageChange1, setIsImageChange1] = useState(false);
  const [oldImageName1, setOldImageName1] = useState("");

  const [imagePreview2, setImagePreview2] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [imageFileUrl2, setImageFileUrl2] = useState("");
  const [isImageChange2, setIsImageChange2] = useState(false);
  const [oldImageName2, setOldImageName2] = useState("");

  const [textValue1, setTextValue1] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [textValueMM1, setTextValueMM1] = useState(
    RichTextEditor.createEmptyValue()
  );
  // text value 2
  const [textValue2, setTextValue2] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [textValueMM2, setTextValueMM2] = useState(
    RichTextEditor.createEmptyValue()
  );

  // text value 3
  const [textValue3, setTextValue3] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [textValueMM3, setTextValueMM3] = useState(
    RichTextEditor.createEmptyValue()
  );

  const { data: articleCat } = useQuery(ARTICLE_CAT, {
    variables: {
      is_traditional: isTraditional,
    },
    fetchPolicy: "network-only",
  });
  const [loadArticle, resultArticle] = useLazyQuery(ONE_ARTICLE);

  useEffect(() => {
    loadArticle({ variables: { id: id } });
  }, [loadArticle]);

  useEffect(() => {
    if (resultArticle.data) {
      setValues({
        id: resultArticle?.data.articles_by_pk.id ?? "",
        image_url: resultArticle?.data.articles_by_pk.image_url ?? "",
        image_url_1: resultArticle?.data.articles_by_pk.image_url_1 ?? "",
        image_url_2: resultArticle?.data.articles_by_pk.image_url_2 ?? "",
        name: resultArticle?.data.articles_by_pk.name ?? "",
        name_mm: resultArticle?.data.articles_by_pk.name_mm ?? "",
        is_traditional: resultArticle?.data.articles_by_pk.is_traditional ?? "",
        description_1: resultArticle?.data.articles_by_pk.description_1 ?? "",
        description_1_mm:
          resultArticle?.data.articles_by_pk.description_1_mm ?? "",
        duration_time: resultArticle?.data.articles_by_pk.duration_time ?? "",
        fk_article_category_id:
          resultArticle?.data.articles_by_pk.fk_article_category_id ?? "",
      });
      setIsTraditional(
        resultArticle?.data.articles_by_pk?.is_traditional ?? ""
      );
      setTextValue1(
        RichTextEditor.createValueFromString(
          resultArticle.data?.articles_by_pk?.description_1,
          "html"
        )
      );
      setTextValueMM1(
        RichTextEditor.createValueFromString(
          resultArticle.data?.articles_by_pk?.description_1_mm,
          "html"
        )
      );
      // description_2
      setTextValue2(
        RichTextEditor.createValueFromString(
          resultArticle.data?.articles_by_pk?.description_2,
          "html"
        )
      );
      setTextValueMM2(
        RichTextEditor.createValueFromString(
          resultArticle.data?.articles_by_pk?.description_2_mm,
          "html"
        )
      );
      // description_3
      setTextValue3(
        RichTextEditor.createValueFromString(
          resultArticle.data.articles_by_pk?.description_3,
          "html"
        )
      );
      setTextValueMM3(
        RichTextEditor.createValueFromString(
          resultArticle.data?.articles_by_pk?.description_3_mm,
          "html"
        )
      );

      // image url
      setImagePreview(resultArticle?.data.articles_by_pk.image_url);
      let image = resultArticle.data.articles_by_pk.image_url;
      setOldImageName(
        image?.substring(image.lastIndexOf("/") + 1, image.lenght)
      );
      // image url_1
      setImagePreview1(resultArticle?.data?.articles_by_pk?.image_url_1);
      let image1 = resultArticle?.data?.articles_by_pk?.image_url_1;
      setOldImageName1(
        image1?.substring(image1?.lastIndexOf("/") + 1, image1?.lenght)
      );

      // image url 2
      setImagePreview2(resultArticle?.data?.articles_by_pk?.image_url_2);
      let image2 = resultArticle.data?.articles_by_pk?.image_url_2;
      setOldImageName2(
        image2?.substring(image2.lastIndexOf("/") + 1, image2.lenght)
      );
    }
  }, [resultArticle]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  //--------------------------------------------
  //for image upload
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      // alert("Error on Server");
      console.log("error ", error);
    },
    onCompleted: (result) => {
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsImageChange(true);
      setValues({
        ...values,
        image_url: `https://axra.sgp1.digitaloceanspaces.com/Mula/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const chooseImage = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!imageType.includes(image.type)) {
        setError({
          ...error,
          image_url: "Please Select image (png,jpeg)",
        });
        return;
      }
      if (image.size > 10485760) {
        setError({
          ...error,
          image_url: "Image size must be smaller than 10MB",
        });
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      await getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  const [delete_image] = useMutation(DELETE_IMAGE, {
    onError: (err) => {
      // alert("Error on Server");
      setLoading(false);
    },
  });
  //----------------------------------------

  // image url 1

  //for image upload
  const [getImageUrl1] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      // alert("Error on Server");
      console.log("error ", error);
    },
    onCompleted: (result) => {
      setImageFileUrl1(result.getImageUploadUrl.imageUploadUrl);
      setIsImageChange1(true);
      setValues({
        ...values,
        image_url_1: `https://axra.sgp1.digitaloceanspaces.com/Mula/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const chooseImage1 = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      console.log("image 1 ", image);
      if (!imageType.includes(image.type)) {
        setError({
          ...error,
          image_url_1: "Please Select image (png,jpeg)",
        });
        return;
      }
      if (image.size > 10485760) {
        setError({
          ...error,
          image_url_1: "Image size must be smaller than 10MB",
        });
        return;
      }
      setImageFile1(image);
      setImagePreview1(URL.createObjectURL(image));
      await getImageUrl1({ variables: { contentType: "image/*" } });
    }
  };

  const [delete_image1] = useMutation(DELETE_IMAGE, {
    onError: (err) => {
      // alert("Error on Server");
      setLoading(false);
    },
  });

  //----------------------------------------
  // image url 2
  //for image upload
  const [getImageUrl2] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      // alert("Error on Server");
      console.log("error ", error);
    },
    onCompleted: (result) => {
      console.log("get iamge 2 ", result.getImageUploadUrl.imageName);
      setImageFileUrl2(result.getImageUploadUrl.imageUploadUrl);
      setIsImageChange2(true);
      setValues({
        ...values,
        image_url_2: `https://axra.sgp1.digitaloceanspaces.com/Mula/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const chooseImage2 = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];

      if (!imageType.includes(image.type)) {
        setError({
          ...error,
          image_url_2: "Please Select image (png,jpeg)",
        });
        return;
      }
      if (image.size > 10485760) {
        setError({
          ...error,
          image_url_2: "Image size must be smaller than 10MB",
        });
        return;
      }
      setImageFile2(image);
      setImagePreview2(URL.createObjectURL(image));
      getImageUrl2({ variables: { contentType: "image/*" } });
    }
  };

  const [delete_image2] = useMutation(DELETE_IMAGE, {
    onError: (err) => {
      // alert("Error on Server");
      setLoading(false);
    },
    onCompleted: (data) => {},
  });

  //------------------------
  const [update_article] = useMutation(UPDATE_ARTICLE, {
    onError: (err) => {
      alert("Error on server");
      setLoading(false);
    },
    onCompleted: (result) => {
      console.log("result ", result);
      setLoading(false);
      setTextValue1(RichTextEditor.createEmptyValue());
      setTextValue2(RichTextEditor.createEmptyValue());
      setTextValue3(RichTextEditor.createEmptyValue());
      setValues({});
      alert("New Article has been updated");
      navigate(-1);
    },
    refetchQueries: [ONE_ARTICLE],
  });

  //-------------------------------------------------------------
  // description_1
  const onChange1 = (value) => {
    setTextValue1(value);
    setValues({ ...values, description_1: value.toString("html") });
  };
  const onChangeMM1 = (value) => {
    setTextValueMM1(value);
    setValues({ ...values, description_1_mm: value.toString("html") });
  };

  // description_2
  const onChange2 = (value) => {
    setTextValue2(value);
    setValues({ ...values, description_2: value.toString("html") });
  };
  const onChangeMM2 = (value) => {
    setTextValueMM2(value);
    setValues({ ...values, description_2_mm: value.toString("html") });
  };

  // description_3
  const onChange3 = (value) => {
    setTextValue3(value);
    setValues({ ...values, description_3: value.toString("html") });
  };
  const onChangeMM3 = (value) => {
    setTextValueMM3(value);
    setValues({ ...values, description_3_mm: value.toString("html") });
  };
  //---------------------------------------------------------------

  const handleUpdate = async () => {
    setLoading(true);
    let isErrorExit = false;
    let errorObject = {};
    if (!values.fk_article_category_id) {
      isErrorExit = true;
      errorObject.fk_article_category_id = "Article Category is required";
    }
    if (!values.name) {
      isErrorExit = true;
      errorObject.name = "Article name Eng is required";
    }
    if (!values.name_mm) {
      isErrorExit = true;
      errorObject.name_mm = "Article name MM is required";
    }
    if (!values.image_url) {
      isErrorExit = true;
      errorObject.image_url = "Image url is required";
    }

    if (!values.description_1) {
      isErrorExit = true;
      errorObject.description_1 = "Description Eng is required";
    }

    if (!values.duration_time) {
      isErrorExit = true;
      errorObject.duration_time = "duration_time  is required";
    }

    if (isErrorExit) {
      console.log("err obj", errorObject);
      setError(errorObject);
      setLoading(false);
      return;
    }

    try {
      if (isImageChange) {
        await imageService.uploadImage(imageFileUrl, imageFile);
        await delete_image({ variables: { image_name: oldImageName } });
      }

      if (isImageChange1) {
        console.log("image change 1 ----------------");
        await imageService.uploadImage(imageFileUrl1, imageFile1);
        await delete_image1({ variables: { image_name: oldImageName1 } });
      }
      if (isImageChange2) {
        console.log("iamge change 2 ---------------");
        await imageService.uploadImage(imageFileUrl2, imageFile2);
        await delete_image2({ variables: { image_name: oldImageName2 } });
      }

      await update_article({
        variables: {
          ...values,
          is_traditional: isTraditional,
          duration_time: Number(values.duration_time),
        },
      });
    } catch (error) {
      console.log("Error ", error);
    }
  };

  return (
    <>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          {/* dashboard */}
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              {/* <Link to="/" className="dashboard"> */}
              <Typography variant="h6">Mula Dashboard (Article)</Typography>
            </Breadcrumbs>
            <Typography>Main / Article</Typography>
          </div>
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </div>

        <CardContent sx={{ p: 3 }} elevation={4}>
          <Box display="flex" gap="2rem" justifyContent="center">
            <Box>
              <Box display="flex" gap="1rem" justifyContent="center" mb="1rem">
                {/* image url */}
                <Box>
                  <Box className="image">
                    <CardMedia
                      component="img"
                      height="200px"
                      image={imagePreview}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center" mb="1rem">
                    {/* image */}
                    <FormControl
                      sx={{
                        maxWidth: "70%",
                      }}
                      //   className="photoCamera"
                    >
                      <Typography
                        sx={{
                          mb: 1,
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        Rendered size must be 1920 * 1080 px and Aspect ratio
                        must be 16:9Cli
                      </Typography>
                      <Button
                        variant="contained"
                        component="label"
                        size="large"
                        // sx={{ py: "0.5rem" }}
                      >
                        <CloudUploadSharpIcon />
                        <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                        <input
                          hidden
                          onChange={chooseImage}
                          accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                          type="file"
                          error={error["image_url"]}
                        />
                      </Button>
                      <FormHelperText error>
                        {error["image_url"]}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
                {/* image url_1 */}
                <Box>
                  <Box className="image">
                    <CardMedia
                      component="img"
                      height="200px"
                      image={imagePreview1}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center" mb="1rem">
                    {/* image */}
                    <FormControl
                      sx={{
                        maxWidth: "70%",
                      }}
                      //   className="photoCamera"
                    >
                      <Typography
                        sx={{
                          mb: 1,
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        Rendered size must be 1920 * 1080 px and Aspect ratio
                        must be 16:9Cli
                      </Typography>
                      <Button
                        variant="contained"
                        component="label"
                        size="large"
                        // sx={{ py: "0.5rem" }}
                      >
                        <CloudUploadSharpIcon />
                        <Typography sx={{ ml: 1 }}>Upload Image 1</Typography>
                        <input
                          hidden
                          onChange={chooseImage1}
                          accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                          type="file"
                          error={error["image_url_1"]}
                        />
                      </Button>
                      <FormHelperText error>
                        {error["image_url_1"]}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
                {/* image url_2 */}
                <Box>
                  <Box className="image">
                    <CardMedia
                      component="img"
                      height="200px"
                      image={imagePreview2}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center" mb="1rem">
                    {/* image */}
                    <FormControl
                      sx={{
                        maxWidth: "70%",
                      }}
                      //   className="photoCamera"
                    >
                      <Typography
                        sx={{
                          mb: 1,
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        Rendered size must be 1920 * 1080 px and Aspect ratio
                        must be 16:9Cli
                      </Typography>
                      <Button
                        variant="contained"
                        component="label"
                        size="large"
                        // sx={{ py: "0.5rem" }}
                      >
                        <CloudUploadSharpIcon />
                        <Typography sx={{ ml: 1 }}>Upload Image 2</Typography>
                        <input
                          hidden
                          onChange={chooseImage2}
                          accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                          type="file"
                          error={error["image_url_2"]}
                        />
                      </Button>
                      <FormHelperText error>
                        {error["image_url_2"]}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Traditional or Digital */}
          <Box sx={{ my: "1rem", display: "flex", justifyContent: "center" }}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Choose Traditional or Digital
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isTraditional}
                onChange={(e) => setIsTraditional(e.target.value)}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Traditional"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Digital"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            className="grid_container"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              // gridTemplateColumns: repeat(auto - fit, minmax("200px, 1fr")),
              px: "0.5rem",
              rowGap: "1rem",
              columnGap: "5rem",
              mb: "2rem",
            }}
          >
            {/* article Category*/}
            <FormControl>
              <FormLabel style={{ fontWeight: "bold" }}>
                Article Category
              </FormLabel>
              {/* <InputLabel id="sub_type">Ownership</InputLabel> */}
              <Select
                style={{ height: "50px" }}
                variant="outlined"
                defaultValue=""
                value={values?.fk_article_category_id}
                onChange={handleChange("fk_article_category_id")}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled={true} hidden>
                  Enter Article Category
                </MenuItem>

                {Array.isArray(articleCat?.article_category)
                  ? articleCat?.article_category.map((article) => (
                      <MenuItem key={article?.id} value={article?.id}>
                        {console.log("article name -------", article?.name)}
                        {article?.name}
                      </MenuItem>
                    ))
                  : null}
              </Select>
              {error.fk_article_category_id && (
                <FormHelperText error>
                  {error.fk_article_category_id}
                </FormHelperText>
              )}
            </FormControl>
            {/* article Name Eng */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Article Name</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="name"
                placeholder="Enter Article Name (Eng)"
                value={values.name}
                onChange={handleChange("name")}
                error={error.name ? true : false}
                helperText={error.name}
              />
            </FormControl>

            {/* Article Name MM */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Article Name MM</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="name_mm"
                placeholder="Enter Article Name (MM)"
                value={values.name_mm}
                onChange={handleChange("name_mm")}
                error={error.name_mm ? true : false}
                helperText={error.name_mm}
              />
            </FormControl>

            {/* duration in time */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>
                Duration In Time
              </FormLabel>
              <TextField
                type="number"
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="duration_time"
                placeholder="Enter Duration"
                value={values.duration_time}
                onChange={handleChange("duration_time")}
                error={error.duration_time ? true : false}
                helperText={error.duration_time}
              />
            </FormControl>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            rowGap="1rem"
            columnGap="5rem"
            px="0.5rem"
          >
            {/* description 1 Eng */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description 1
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChange1}
                value={textValue1}
                toolbarConfig={toolbarConfig}
              />
              {error.description_1 && (
                <FormHelperText error>{error.description_1}</FormHelperText>
              )}
            </Box>

            {/* description 1 mm */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description 1
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChangeMM1}
                value={textValueMM1}
                toolbarConfig={toolbarConfig}
              />
              {error.description_1_mm && (
                <FormHelperText error> {error.description_1_mm}</FormHelperText>
              )}
            </Box>
            {/* description 2 Eng */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description 2
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChange2}
                value={textValue2}
                toolbarConfig={toolbarConfig}
              />
              {error.description_2 && (
                <FormHelperText error>{error.description_2}</FormHelperText>
              )}
            </Box>

            {/* description 2 mm */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description 2 MM
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChangeMM2}
                value={textValueMM2}
                toolbarConfig={toolbarConfig}
              />
              {error.description_2_mm && (
                <FormHelperText error> {error.description_2_mm}</FormHelperText>
              )}
            </Box>
            {/* description 3 Eng */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description 3
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChange3}
                value={textValue3}
                toolbarConfig={toolbarConfig}
              />
              {error.description_3 && (
                <FormHelperText error>{error.description_3}</FormHelperText>
              )}
            </Box>

            {/* description 3 mm */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description 3 MM
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChangeMM3}
                value={textValueMM3}
                toolbarConfig={toolbarConfig}
              />
              {error.description_3_mm && (
                <FormHelperText error> {error.description_3_mm}</FormHelperText>
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end", m: "2rem" }}>
            <LoadingButton
              variant="contained"
              onClick={handleUpdate}
              loading={loading}
            >
              Update
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default UpdateArticle;
