import { useEffect, useState } from "react";

import {
  Button,
  Box,
  Breadcrumbs,
  InputBase,
  TableRow,
  TablePagination,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
  Avatar,
  Table,
  styled,
  TableCell,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ALL_EVENTS } from "../../gql/event";
import { makeStyles } from "@material-ui/core/styles";
import { ALL_VIDEOS } from "../../gql/videoUpload";
import { ALL_ARTICLES } from "../../gql/article";

const Articles = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const [isTraditional, setIsTraditional] = useState();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [articles, setArticles] = useState();
  const [loadArticle, resultArticle] = useLazyQuery(ALL_ARTICLES, {
    variables: {
      articleQuery: {
        _or: {
          ...(search
            ? {
                name: { _ilike: `%${search}%` },
              }
            : {}),

          ...(isTraditional
            ? {
                is_traditional: { _eq: `${isTraditional}` },
              }
            : {}),
        },
      },
    },
  });

  useEffect(() => {
    loadArticle({
      variables: {
        limit: rowsPerPage,
        offset: offset,
      },
      fetchPolicy: "network-only",
    });
  }, [loadArticle, rowsPerPage, offset, search]);

  useEffect(() => {
    if (resultArticle.data) {
      setArticles(resultArticle.data.articles);
      setCount(resultArticle.data?.articles_aggregate.aggregate.count);
    }
  }, [resultArticle]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchValue);
    if (searchValue === "") {
      loadArticle({
        variables: {
          limit: rowsPerPage,
          offset: offset,
        },
        fetchPolicy: "network-only",
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  if (!articles) {
    return;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        {/* dashboard */}
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard" className="dashboard">
              Dashboard
            </Link>
            <span>Article</span>
          </Breadcrumbs>
        </div>
        {/* search */}
        <div>
          <form onSubmit={handleSearch}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 350,
              }}
            >
              {/* Search Box */}
              <InputBase
                id="search-by-name"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search By Video Name"
                type="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                //   color="warning"
                sx={{ p: "10px" }}
                aria-label="directions"
                type="submit"
                value={search}
                onClick={handleSearch}
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </form>
        </div>

        {/* add */}
        <Button
          variant="contained"
          sx={{
            px: 3,
            py: 1,
          }}
          color="secondary"
          onClick={() => navigate("/create_article")}
        >
          Add
        </Button>
      </div>

      {/* Traditioinal or Digital */}
      <Box sx={{ my: "1rem", display: "flex", justifyContent: "center" }}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Choose Traditional or Digital
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={isTraditional}
            onChange={(e) => setIsTraditional(e.target.value)}
            row
          >
            <FormControlLabel value="" control={<Radio />} label="All" />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Traditional"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Digital"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            // maxHeight: "70vh",
            Width: "100px",
            border: "1px groove rgba(0,0,0,0.2)",
          }}
        >
          <Table stickyHeader aria-label="sticky table , responsive table">
            <TableHead>
              <StyledTableRow>
                <TableCell
                  style={{
                    minWidth: 100,
                    fontWeight: "bold",
                  }}
                >
                  ID
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Image
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Article Category
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Name Eng
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Traditioinal or Digital
                </TableCell>

                <TableCell>Status</TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </StyledTableRow>
            </TableHead>

            {articles.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Articles Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {articles &&
                  articles.map((row, index) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <Avatar
                          width="52px"
                          height="52px"
                          src={row.image_url}
                        ></Avatar>
                      </TableCell>
                      <TableCell>{row?.article_category?.name}</TableCell>
                      <TableCell>{row.name?.substring(0, 20)} ...</TableCell>
                      <TableCell>
                        {row?.is_traditional === true
                          ? "Traditional"
                          : "Digital"}
                        ...
                      </TableCell>
                      <TableCell>
                        {row.pending ? (
                          <Typography
                            sx={{
                              backgroundColor: "orange",
                              p: 1,
                              color: "#fff",
                              borderRadius: "3px",
                              textAlign: "center",
                            }}
                          >
                            Pending
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              backgroundColor: "green",
                              textAlign: "center",
                              p: 1,
                              color: "#fff",
                              borderRadius: "3px",
                            }}
                          >
                            Approve
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          sx={{ color: "white", p: 1, mr: 1 }}
                          onClick={() => navigate(`/article/${row.id}`)}
                        >
                          Detail
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ color: "black" }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default Articles;
