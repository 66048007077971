import { useMutation, useQuery } from "@apollo/client";
import {
  CardContent,
  FormControl,
  InputLabel,
  Typography,
  Button,
  Card,
  Breadcrumbs,
  FormHelperText,
  Select,
  Box,
  MenuItem,
  FormLabel,
  TextField,
  CardMedia,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";
import { ADD_RESELLER, ALL_RESELLER, USERID } from "../../gql/reseller";
import RichTextEditor from "react-rte";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import imageService from "../../services/image";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

import { ADD_VIDEO_CREATOR, ALL_VIDEO_CREATOR } from "../../gql/videoCreator";
import { IMAGE_UPLOAD } from "../../gql/image";

const imageType = ["image/jpeg", "image/png"];
const CreateVideoCateory = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [isTraditional, setIsTraditional] = useState(true);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      alert("Error on Server");
      console.log("error ", error);
    },
    onCompleted: (result) => {
      return result;
      // setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      // setValues({
      //   ...values,
      //   image_url: `https://axra.sgp1.digitaloceanspaces.com/Mula/${result.getImageUploadUrl.imageName}`,
      // });
    },
  });

  const chooseImage = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!imageType.includes(image.type)) {
        setErrors({
          ...errors,
          image_url: "Please Select image (png,jpeg)",
        });
        return;
      }
      if (image.size > 10485760) {
        setErrors({
          ...errors,
          image_url: "Image size must be smaller than 10MB",
        });
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      setValues({ ...values, image_url: URL.createObjectURL(image) });
      // getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  const [addVideoCat] = useMutation(ADD_VIDEO_CREATOR, {
    onError: (err) => {
      alert("Error on Server");
    },
    onCompleted: (result) => {
      setValues({});
      alert("New Video Creator has been added");
      navigate("/video_creator");
    },
    refetchQueries: [ALL_VIDEO_CREATOR],
  });

  const handleCreate = async (e) => {
    let isErrorExit = false;
    let errorObject = {};
    setLoading(true);
    if (!values.name) {
      isErrorExit = true;
      errorObject.name = "Name is required";
    }
    if (!values.image_url) {
      isErrorExit = true;
      errorObject.image_url = "image_url is required";
    }

    if (isErrorExit) {
      setErrors(errorObject);

      return;
    }
    try {
    } catch (error) {
      console.log("Error ", error);
    }
    const uploadUrl = await getImageUrl({
      variables: { contentType: "image/*" },
    });

    await imageService.uploadImage(
      uploadUrl.data.getImageUploadUrl.imageUploadUrl,
      imageFile
    );
    await addVideoCat({
      variables: {
        ...values,
        is_traditional: isTraditional,
        image_url: `https://axra.sgp1.digitaloceanspaces.com/Mula/${uploadUrl.data.getImageUploadUrl.imageName}`,
      },
    });
  };

  return (
    <>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          {/* dashboard */}
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              {/* <Link to="/" className="dashboard"> */}
              <Typography variant="h6">
                Mula Dashboard (Video Creator)
              </Typography>

              {/* </Link> */}
              {/* <span>ArtWork</span> */}
            </Breadcrumbs>
            <Typography>Main / Video Creator</Typography>
          </div>
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </div>
        <CardContent sx={{ p: "2rem" }}>
          <Box
            sx={{
              display: "grid",
              rowGap: "2rem",
            }}
          >
            <Box className="image">
              <CardMedia
                component="img"
                height="200px"
                image={imagePreview}
                // sx={{ my: 2 }}
              />
            </Box>
            <Box display="flex" justifyContent="center" mb="1rem">
              {/* image */}
              <FormControl
                sx={{
                  maxWidth: "20%",
                }}
                className="photoCamera"
              >
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Rendered size must be 1920 * 1080 px and Aspect ratio must be
                  16:9Cli
                </Typography>
                <Button
                  variant="contained"
                  component="label"

                  // sx={{ py: "0.5rem" }}
                >
                  {/* <PhotoCamera /> */}
                  <CloudUploadSharpIcon />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={chooseImage}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                    error={errors["image_url"]}
                  />
                </Button>
                <FormHelperText error>{errors["image_url"]}</FormHelperText>
              </FormControl>
            </Box>

            <Box sx={{ my: "1rem" }}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Choose Traditional or Digital
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={isTraditional}
                  onChange={(e) => setIsTraditional(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Traditional"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Digital"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {/* name */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Creator Name</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="cat_name"
                placeholder="Enter Category Name"
                value={values.name}
                onChange={handleChange("name")}
                error={errors.name ? true : false}
                helperText={errors.name}
              />
            </FormControl>
            {/* name mm*/}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Creator Name MM</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="cat_name"
                placeholder="Enter Category Name MM"
                value={values.name_mm}
                onChange={handleChange("name_mm")}
                error={errors.name_mm ? true : false}
                helperText={errors.name_mm}
              />
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-end" my="2rem">
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleCreate}
            >
              Create
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
export default CreateVideoCateory;
