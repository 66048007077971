import { gql } from "@apollo/client";
//Get all articless
export const ALL_ARTICLES = gql`
  query MyQuery(
    $limit: Int!
    $offset: Int!
    $articleQuery: articles_bool_exp!
  ) {
    articles(
      offset: $offset
      limit: $limit
      order_by: { updated_at: desc }
      where: $articleQuery
    ) {
      created_at
      description_1
      description_1_mm
      duration_time
      is_traditional
      id
      image_url
      pending
      name
      name_mm
      updated_at
      article_category {
        id
        name
      }
    }
    articles_aggregate {
      aggregate {
        count
      }
    }
  }
`;

//get articles by id
export const ONE_ARTICLE = gql`
  query MyQuery($id: Int!) {
    articles_by_pk(id: $id) {
      created_at
      description_1
      description_1_mm
      description_2
      description_2_mm
      description_3
      description_3_mm
      duration_time
      id
      is_traditional
      pending
      image_url
      image_url_1
      image_url_2
      name
      updated_at
      name_mm
      article_category {
        id
        name
      }
    }
  }
`;

//Create articles
export const CREATE_ARTICLE = gql`
  mutation MyMutation(
    $description_1: String!
    $description_1_mm: String
    $description_2: String
    $description_2_mm: String
    $description_3: String
    $description_3_mm: String
    $image_url: String!
    $image_url_1: String
    $image_url_2: String
    $name: String!
    $duration_time: Int!
    $name_mm: String!
    $fk_article_category_id: Int!
    $is_traditional: Boolean!
  ) {
    insert_articles_one(
      object: {
        description_1: $description_1
        description_1_mm: $description_1_mm
        description_2: $description_2
        description_2_mm: $description_2_mm
        description_3: $description_3
        description_3_mm: $description_3_mm
        image_url: $image_url
        image_url_2: $image_url_2
        image_url_1: $image_url_1
        name: $name
        duration_time: $duration_time
        name_mm: $name_mm
        fk_article_category_id: $fk_article_category_id
        is_traditional: $is_traditional
      }
    ) {
      created_at
      description_1_mm
      description_1
      duration_time
      id
      image_url
      name
      name_mm
      updated_at
    }
  }
`;

//update articles
export const UPDATE_ARTICLE = gql`
  mutation MyMutation(
    $id: Int!
    $description_1: String!
    $description_1_mm: String
    $description_2: String
    $description_2_mm: String
    $description_3: String
    $description_3_mm: String
    $image_url: String!
    $image_url_1: String
    $image_url_2: String
    $name: String!
    $duration_time: Int!
    $name_mm: String!
    $fk_article_category_id: Int!
    $is_traditional: Boolean!
  ) {
    update_articles_by_pk(
      pk_columns: { id: $id }
      _set: {
        description_1: $description_1
        description_1_mm: $description_1_mm
        description_2: $description_2
        description_2_mm: $description_2_mm
        description_3: $description_3
        description_3_mm: $description_3_mm
        image_url: $image_url
        image_url_2: $image_url_2
        image_url_1: $image_url_1
        name: $name
        duration_time: $duration_time
        name_mm: $name_mm
        fk_article_category_id: $fk_article_category_id
        is_traditional: $is_traditional
      }
    ) {
      created_at
      description_1_mm
      description_1
      duration_time
      id
      image_url
      image_url_1
      image_url_2
      name_mm
      name
      is_traditional
      updated_at
      article_category {
        id
        name
      }
    }
  }
`;

//delete articles
export const DELETE_ARTICLE = gql`
  mutation MyMutation($id: Int!) {
    delete_articles_by_pk(id: $id) {
      id
    }
  }
`;

//pending status
export const PENDING_STATUS = gql`
  mutation pending_status($id: Int!, $pending: Boolean!) {
    update_articles_by_pk(
      pk_columns: { id: $id }
      _set: { pending: $pending }
    ) {
      id
      pending
    }
  }
`;

//for article category
export const ARTICLE_CAT = gql`
  query aa($is_traditional: Boolean!) {
    article_category(where: { is_traditional: { _eq: $is_traditional } }) {
      id
      name
    }
  }
`;
