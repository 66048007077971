import { gql } from "@apollo/client";
//get all digital artwork
export const All_DIGITAL_ARTWORKS = gql`
  query MyQuery($limit: Int!, $offset: Int!, $search: String!) {
    digital_art_work(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: { artwork_name: { _ilike: $search } }
    ) {
      id
      artwork_name
      artwork_image_url
      artwork_year
      current_price
      update_price
      pending
      description_mm
      description
      artwork_name_mm
    }
    digital_art_work_aggregate {
      aggregate {
        count
      }
    }
  }
`;

//delete digital artwork
export const DELETE_DIGITAL_ARTWORK = gql`
  mutation MyMutation($id: Int!) {
    delete_digital_art_work_by_pk(id: $id) {
      id
    }
  }
`;

// add digital artwork
export const ADD_DIGITAL_ARTWORK = gql`
  mutation add_digital_artwork(
    $artwork_image_url: String!
    $artwork_name: String!
    $artwork_name_mm: String!
    $artwork_year: Int!
    $current_price: Int!
    $disabled: Boolean!
    $fk_artist_id: Int!
    $pending: Boolean!
    $update_price: Int
    $description_mm: String!
    $description: String!
  ) {
    insert_digital_art_work_one(
      object: {
        artwork_image_url: $artwork_image_url
        artwork_name: $artwork_name
        artwork_name_mm: $artwork_name_mm
        artwork_year: $artwork_year
        current_price: $current_price
        disabled: $disabled
        fk_artist_id: $fk_artist_id
        pending: $pending
        update_price: $update_price
        description_mm: $description_mm
        description: $description
      }
    ) {
      id
    }
  }
`;

//get digital artwork id
export const DIGITAL_ARTWORK_ID = gql`
  query digital_artwork_by_pk($artWorkId: Int!) {
    digital_art_work_by_pk(id: $artWorkId) {
      artwork_image_url
      artwork_name
      artwork_name_mm
      artwork_year
      created_at
      current_price
      disabled
      fk_artist_id
      id
      fk_ownership_id
      pending
      update_price
      updated_at
      description_mm
      description
      digtal_art_series {
        series_name
        id
      }
      digital_art_work_artist {
        artist_name
        artist_name_mm
        id
      }
      user {
        fullname
      }
    }
  }
`;

//updae digital artwork
export const UPDATE_DIGITAL_ARTWORK = gql`
  mutation update_digital_artwork(
    $id: Int!
    $artwork_image_url: String!
    $artwork_name: String!
    $artwork_name_mm: String!
    $artwork_year: Int!
    $current_price: Int!
    $disabled: Boolean!
    $fk_artist_id: Int!
    $pending: Boolean!
    $update_price: Int
    $description_mm: String!
    $description: String!
  ) {
    update_digital_art_work_by_pk(
      pk_columns: { id: $id }
      _set: {
        artwork_image_url: $artwork_image_url
        artwork_name: $artwork_name
        artwork_name_mm: $artwork_name_mm
        artwork_year: $artwork_year
        current_price: $current_price
        disabled: $disabled
        fk_artist_id: $fk_artist_id
        pending: $pending
        update_price: $update_price
        description_mm: $description_mm
        description: $description
      }
    ) {
      artwork_image_url
      artwork_name
      artwork_name_mm
      artwork_year
      created_at
      current_price
      disabled
      digital_art_work_artist {
        artist_name
        id
      }
      fk_artist_id
      id
      pending
      update_price
      updated_at
    }
  }
`;

//disable or Enable Artwork
export const EDIT_DIGITAL_ARTWORK = gql`
  mutation MyMutation($id: Int!, $disabled: Boolean!) {
    update_digital_art_work_by_pk(
      pk_columns: { id: $id }
      _set: { disabled: $disabled }
    ) {
      disabled
      id
    }
  }
`;

//pending status
export const DIGITAL_PENDING_STATUS = gql`
  mutation pending_status($id: Int!, $pending: Boolean!) {
    update_digital_art_work_by_pk(
      pk_columns: { id: $id }
      _set: { pending: $pending }
    ) {
      id
      pending
    }
  }
`;

//get artist name
export const DIGITAL_ARTIST_NAME = gql`
  query MyQuery {
    artist(
      where: {
        disabled: { _eq: false }
        traditional_or_digital_preferred: { _eq: "Digital" }
      }
    ) {
      id
      artist_name
    }
  }
`;

//get artist art-series
export const DIGITAL_ARTIDT_ART_SERIES = gql`
  mutation add($fk_digital_artwork_id: Int!, $fk_digital_artseries_id: Int!) {
    insert_digital_artist_art_series_one(
      object: {
        fk_digital_artwork_id: $fk_digital_artwork_id
        fk_digital_artseries_id: $fk_digital_artseries_id
      }
    ) {
      fk_digital_artseries_id
      fk_digital_artwork_id
      id
    }
  }
`;

//get digital art_sereis and add to digital_artist_art_series
export const DIGITAL_ART_SERIES = gql`
  query MyQuery($artist_id: Int!) {
    digtal_art_series(where: { fk_artist_id: { _eq: $artist_id } }) {
      series_name
      id
      digital_artist_art_series {
        fk_digital_artseries_id
        fk_digital_artwork_id
      }
    }
  }
`;

//get art_sereis by pk for update
export const DIGITAL_ART_SERIES_BY_PK = gql`
  query MyQuery($artwork_id: Int!) {
    digital_artist_art_series(
      where: { fk_digital_artwork_id: { _eq: $artwork_id } }
    ) {
      fk_digital_artseries_id
      digtal_art_sery {
        series_name
        id
      }
    }
  }
`;

//delete digital art_sereis

export const DELETE_DIGITAL_ART_SERIES = gql`
  mutation MyMutation($artwork_id: Int!) {
    delete_digital_artist_art_series(
      where: { fk_digital_artwork_id: { _eq: $artwork_id } }
    ) {
      returning {
        id
      }
    }
  }
`;

//add digital add series
export const ADD_DIGITAL_ART_SERIES = gql`
  mutation MyMutation(
    $fk_digital_artseries_id: Int!
    $fk_digital_artwork_id: Int!
  ) {
    insert_digital_artist_art_series_one(
      object: {
        fk_digital_artseries_id: $fk_digital_artseries_id
        fk_digital_artwork_id: $fk_digital_artwork_id
      }
    ) {
      id
      fk_digital_artseries_id
      fk_digital_artwork_id
    }
  }
`;
