import { gql } from "@apollo/client";
//get all article category
export const ALL_VIDEO_CREATOR = gql`
  query aa($search: String!) {
    video_creator(
      order_by: { updated_at: desc }
      where: { name: { _ilike: $search } }
    ) {
      id
      name
      name_mm
      is_traditional
      image_url
      follow_count
    }
  }
`;

//article cat by pk
export const VIDEO_CREATOR_BY_PK = gql`
  query aa($id: Int!) {
    video_creator_by_pk(id: $id) {
      id
      name
      name_mm
      is_traditional
      image_url
      follow_count
    }
  }
`;

//add article cat
export const ADD_VIDEO_CREATOR = gql`
  mutation aa(
    $name: String!
    $image_url: String
    $name_mm: String
    $is_traditional: Boolean!
  ) {
    insert_video_creator_one(
      object: {
        name: $name
        image_url: $image_url
        name_mm: $name_mm
        is_traditional: $is_traditional
      }
    ) {
      id
      name
    }
  }
`;

//edit article cat
export const EDIT_VIDEO_CREATOR = gql`
  mutation MyMutation(
    $id: Int!
    $name: String!
    $image_url: String
    $name_mm: String
    $is_traditional: Boolean!
  ) {
    update_video_creator_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        image_url: $image_url
        name_mm: $name_mm
        is_traditional: $is_traditional
      }
    ) {
      name
      id
    }
  }
`;

//delete article cat
export const DELETE_VIDEO_CREATOR = gql`
  mutation delete_video_creator($id: Int!) {
    delete_video_creator_by_pk(id: $id) {
      id
    }
  }
`;
