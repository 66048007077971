import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CardActionArea,
  CardContent,
  FormControl,
  InputLabel,
  Card,
  Breadcrumbs,
  Typography,
  Button,
  CardActions,
  Select,
  Box,
  MenuItem,
  useScrollTrigger,
  FormLabel,
  TextField,
  CardMedia,
  FormHelperText,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import { useDebugValue, useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  ADD_RESELLER,
  ALL_RESELLER,
  RESELLER_ID,
  UPDATE_RESELLER,
  USERID,
} from "../../gql/reseller";
import RichTextEditor from "react-rte";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import {
  ALL_VIDEO_CATS,
  EDIT_VIDEO_CAT,
  VIDEO_CAT_BY_PK,
} from "../../gql/videoCategory";
import {
  ALL_VIDEO_CREATOR,
  EDIT_VIDEO_CREATOR,
  VIDEO_CREATOR_BY_PK,
} from "../../gql/videoCreator";
import { DELETE_IMAGE, IMAGE_UPLOAD } from "../../gql/image";
import imageService from "../../services/image";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
const imageType = ["image/jpeg", "image/png"];
const UpdateVideoCreator = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [isImageChange, setIsImageChange] = useState(false);
  const [oldImageName, setOldImageName] = useState("");
  const [isTraditional, setIsTraditional] = useState(true);
  const { data: videoCatPk } = useQuery(VIDEO_CREATOR_BY_PK, {
    variables: { id: id },
  });

  useEffect(() => {
    if (videoCatPk) {
      setValues({
        id: videoCatPk?.video_creator_by_pk?.id,
        name: videoCatPk?.video_creator_by_pk?.name,
        name_mm: videoCatPk?.video_creator_by_pk?.name_mm,
        is_traditional: videoCatPk?.video_creator_by_pk?.is_traditional,
        image_url: videoCatPk?.video_creator_by_pk?.image_url,
      });
      setIsTraditional(videoCatPk?.video_creator_by_pk?.is_traditional);
      setImagePreview(videoCatPk?.video_creator_by_pk?.image_url);
      let image = videoCatPk?.video_creator_by_pk.image_url;
      setOldImageName(
        image?.substring(image?.lastIndexOf("/") + 1, image?.lenght)
      );
    }
  }, [videoCatPk]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      // alert("Error on Server");
      console.log("error ", error);
    },
    onCompleted: (result) => {
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsImageChange(true);
      setValues({
        ...values,
        image_url: `https://axra.sgp1.digitaloceanspaces.com/Mula/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const chooseImage = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!imageType.includes(image.type)) {
        setErrors({
          ...errors,
          image_url: "Please Select image (png,jpeg)",
        });
        return;
      }
      if (image.size > 10485760) {
        setErrors({
          ...errors,
          image_url: "Image size must be smaller than 10MB",
        });
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  const [delete_image] = useMutation(DELETE_IMAGE, {
    onError: (err) => {
      // alert("Error on Server");
      setLoading(false);
    },
  });

  const [UpdateVideoCreator] = useMutation(EDIT_VIDEO_CREATOR, {
    onError: (err) => {
      alert("Error on Server");
      console.log("update Error", err);
    },
    onCompleted: (result) => {
      console.log("result ", result);
      setValues({});
      alert("Video Creator has been updated");
      navigate("/video_creator");
    },
    refetchQueries: [ALL_VIDEO_CREATOR],
  });

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (isImageChange) {
        await imageService.uploadImage(imageFileUrl, imageFile);
        await delete_image({ variables: { image_name: oldImageName } });
      }

      await UpdateVideoCreator({
        variables: {
          ...values,
          is_traditional: isTraditional,
        },
      });
    } catch (err) {
      console.log("Error");
    }
  };

  return (
    <>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          {/* dashboard */}
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              {/* <Link to="/" className="dashboard"> */}
              <Typography variant="h6">
                Mula Dashboard (Video Creator)
              </Typography>

              {/* </Link> */}
              {/* <span>ArtWork</span> */}
            </Breadcrumbs>
            <Typography>Main / Video Creator</Typography>
          </div>
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </div>
        <CardContent sx={{ p: "2rem" }}>
          <Box
            sx={{
              display: "grid",
              rowGap: "2rem",
            }}
          >
            <Box className="image">
              <CardMedia
                component="img"
                height="200px"
                image={imagePreview}
                // sx={{ my: 2 }}
              />
            </Box>
            <Box display="flex" justifyContent="center" mb="1rem">
              {/* image */}
              <FormControl
                sx={{
                  maxWidth: "20%",
                }}
                className="photoCamera"
              >
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Rendered size must be 1920 * 1080 px and Aspect ratio must be
                  16:9Cli
                </Typography>
                <Button
                  variant="contained"
                  component="label"

                  // sx={{ py: "0.5rem" }}
                >
                  {/* <PhotoCamera /> */}
                  <CloudUploadSharpIcon />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={chooseImage}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                    // error={errors["image_url"]}
                  />
                </Button>
                {/* <FormHelperText error>{errors["image_url"]}</FormHelperText> */}
              </FormControl>
            </Box>
            <Box sx={{ my: "1rem" }}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Choose Traditional or Digital
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={isTraditional}
                  onChange={(e) => setIsTraditional(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Traditional"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Digital"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {/* name */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Creator Name</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="cat_name"
                placeholder="Enter Category Name"
                value={values.name}
                onChange={handleChange("name")}
              />
            </FormControl>
            {/* name mm*/}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Creator Name MM</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="cat_name"
                placeholder="Enter Category Name MM"
                value={values.name_mm}
                onChange={handleChange("name_mm")}
              />
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-end" my="2rem">
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleUpdate}
            >
              Update
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
export default UpdateVideoCreator;
