import React, {
  useState,
  lazy,
  Suspense,
  useEffect,
  createContext,
} from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
// import { Alert } from "@mui/material";

import Header from "../layout/Header";
import SideBar from "../layout/SideBar";

import Dashboard from "../view/dashboard/Dashboard";
import Users from "../view/user/Users";
import User from "../view/user/User";
import CreateUser from "../view/user/CreateUser";

import Artists from "../view/artists/Artists";
import Artist from "../view/artists/Artist";
import CreateArtistByPhone from "./artists/CreateArtistByPhone";
import CreateArtist from "./artists/CreateArtist";
import UpdateArtist from "../view/artists/UpdateArtist";

import Resellers from "../view/reseller/Resellers";
import Reseller from "../view/reseller/Reseller";
import CreateReseller from "../view/reseller/CreateReseller";
import UpdateReseller from "../view/reseller/UpdateReseller";
import DirectCreateReseller from "../view/reseller/DirectCreateReseller";

import ArtWorks from "../view/art_work/ArtWorks";
import ArtWork from "../view/art_work/ArtWork";
import CreateArtWork from "../view/art_work/CreateArtWork";
import UpdateArtWork from "../view/art_work/UpdateArtWork";

import Events from "../view/event/Events";
import Event from "../view/event/Event";
import CreateEvent from "../view/event/CreateEvent";
import UpdateEvent from "../view/event/UpdateEvent";
// video Category
import VideoCategorys from "../view/videoCategory/VideoCategorys";

// video
import Videos from "../view/videoUpload/Videos";
import Video from "../view/videoUpload/Video";
import CreateVideo from "../view/videoUpload/CreateVideo";
import UpdateVideo from "../view/videoUpload/UpdateVideo";

import DigitalArtworks from "../view/digital artwork/DigitalArtworks";
import DigitalArtwork from "../view/digital artwork/DigitalArtwork";
import CreateDigitalArtwork from "../view/digital artwork/CreateDigitalArtwork";
// import Update_digital_artwork from "../view/digital artwork/UpdateDigitalArtwork";
import UpdateDigitalArtwork from "../view/digital artwork/UpdateDigitalArtwork";

import Article from "./article/Article";
import CreateArticle from "./article/CreateArticle";
import Articles from "./article/Articles";
import UpdateArticle from "./article/UpdateArticle";
import Series from "./series/Series";
import FeedBacks from "./feedback/FeedBacks";
import CreateSerie from "./series/CreateSerie";
import UpdateUserPhone from "./user/UpdateUserPhone";

import CreateVideoCateory from "./videoCategory/CreateVideoCategory";
import UpdateVideoCategory from "./videoCategory/UpdateVideoCategory";

import ArticleCategorys from "./articleCategory/ArticleCategorys";
import CreateArticleCategory from "./articleCategory/CreateArticleCategory";
import UpdateArticleCategory from "./articleCategory/UpdateArticleCategory";

import VideoCreators from "./videoCreator/VideoCreators";
import CreateVideoCreator from "./videoCreator/CreateVideoCreator";
import UpdateVideoCreator from "./videoCreator/UpdateVideoCreator";

const drawerWidth = 230;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Admin = () => {
  const [open, setOpen] = useState(true);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const navigate = useNavigate();
  const AuthContext = createContext();
  const [auth, setAuth] = useState(null);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const homeAlert = (message, isError = false) => {
  //   setShowAlert({ message: message, isError: isError });
  //   setTimeout(() => {
  //     setShowAlert({ message: "", isError: false });
  //   }, 3000);
  // };

  useEffect(() => {
    const loggedUser = window.localStorage.getItem("loggedUser");
    if (loggedUser) {
      const parsedLoggedUser = JSON.parse(loggedUser);
      setAuth(parsedLoggedUser);
    } else {
      navigate("/login");
    }
    // if (!loggedUser) {
    //   navigate("/login");
    // }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        //bgcolor: "#F8F7FC",
        //bgcolor: "#1e1e1e",
        background: "#F7F7F7",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawer} />
      <SideBar handleDrawerClose={handleDrawerClose} open={open} />
      <Main open={open}>
        <DrawerHeader />
        <Suspense fallback={<div>Loading...</div>}>
          <AuthContext.Provider value={auth}>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user" element={<Users />} />
              <Route path="/user/:id" element={<User />} />
              <Route path="/create_user" element={<CreateUser />} />
              <Route path="/update_user/:id" element={<UpdateUserPhone />} />

              {/* video creator */}
              <Route path="/video_creator" element={<VideoCreators />} />
              <Route
                path="/create_video_creator"
                element={<CreateVideoCreator />}
              />
              <Route
                path="/video_creator/edit_video_creator/:id"
                element={<UpdateVideoCreator />}
              />
              {/* video category */}
              <Route path="/video_cat" element={<VideoCategorys />} />
              <Route
                path="/create_video_cat"
                element={<CreateVideoCateory />}
              />
              <Route
                path="/video_cat/edit_video_cat/:id"
                element={<UpdateVideoCategory />}
              />
              {/* article category */}
              <Route path="/article_cat" element={<ArticleCategorys />} />
              <Route
                path="/create_article_cat"
                element={<CreateArticleCategory />}
              />
              <Route
                path="/article_cat/edit_article_cat/:id"
                element={<UpdateArticleCategory />}
              />

              {/* artist */}
              <Route path="/artist" element={<Artists />} />
              <Route path="/artist/:id" element={<Artist />} />
              <Route
                path="/create_artist/:id"
                element={<CreateArtistByPhone />}
              />
              <Route path="/create_artist" element={<CreateArtist />} />
              <Route path="/update_artist/:id" element={<UpdateArtist />} />

              <Route path="/reseller" element={<Resellers />} />
              <Route path="/reseller/:id" element={<Reseller />} />
              <Route path="/create_reseller" element={<CreateReseller />} />
              <Route path="/update_reseller/:id" element={<UpdateReseller />} />
              <Route
                path="/create_directReseller/:id"
                element={<DirectCreateReseller />}
              />

              <Route path="/art_work" element={<ArtWorks />} />
              <Route path="/art_work/:id" element={<ArtWork />} />
              <Route path="/create_artWork" element={<CreateArtWork />} />
              <Route path="/update_artWork/:id" element={<UpdateArtWork />} />

              <Route path="/event" element={<Events />} />
              <Route path="/event/:id" element={<Event />} />
              <Route path="/create_event" element={<CreateEvent />} />
              <Route path="/update_event/:id" element={<UpdateEvent />} />

              {/* video upload */}
              <Route path="/video" element={<Videos />} />
              <Route path="/video/:id" element={<Video />} />
              <Route path="/create_video" element={<CreateVideo />} />
              <Route path="/update_video/:id" element={<UpdateVideo />} />

              {/* article */}
              <Route path="/article" element={<Articles />} />
              <Route path="/article/:id" element={<Article />} />
              <Route path="/create_article" element={<CreateArticle />} />
              <Route path="/update_article/:id" element={<UpdateArticle />} />

              {/* series */}

              <Route path="/serie" element={<Series />} />
              <Route path="/create_serie" element={<CreateSerie />} />
              {/* feed back */}

              <Route path="/feedBack" element={<FeedBacks />} />
              <Route path="/create_serie" element={<CreateSerie />} />

              {/* digital artwork */}
              <Route path="/digital_artwork" element={<DigitalArtworks />} />
              <Route path="/digital_artwork/:id" element={<DigitalArtwork />} />
              <Route
                path="/create_digital_artwork"
                element={<CreateDigitalArtwork />}
              />
              <Route
                path="/update_digital_artwork/:id"
                element={<UpdateDigitalArtwork />}
              />
            </Routes>
          </AuthContext.Provider>
        </Suspense>
      </Main>
      {/* {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )} */}
    </Box>
  );
};

export default Admin;
