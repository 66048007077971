import { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Modal,
  Breadcrumbs,
  CardContent,
  CardMedia,
  Card,
  CardActions,
  ListItem,
  Paper,
  ListItemText,
  Alert,
} from "@mui/material";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import {
  ALL_ARTICLES,
  DELETE_ARTICLE,
  ONE_ARTICLE,
  PENDING_STATUS,
} from "../../gql/article";
const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  borderRadius: 1,
  p: 4,
};

const Article = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(ONE_ARTICLE, { variables: { id: id } });

  const [delete_article] = useMutation(DELETE_ARTICLE, {
    onError: (error) => {
      alert("delete error");
    },
    onCompleted: (data) => {
      alert("Article had been deleted");
    },
    refetchQueries: [ALL_ARTICLES],
  });

  const handleRemove = async () => {
    // let image_url = data.articles_by_pk.artist_profile_image_url;
    // console.log("image url", image_url);
    // let image_name = image_url.substring(
    //   image_url.lastIndexOf("/") + 1,
    //   image_url.lenght
    // );
    // await deleteImage({ variables: { image_name: image_name } });
    await delete_article({ variables: { id: id } });
    navigate(-1);
  };

  const handleCloseR = () => setOpen(false);
  const handleRemoveOpen = (row) => {
    setOpen(true);
  };

  const [pending_status] = useMutation(PENDING_STATUS, {
    onError: (err) => {
      alert("Pending Error");
      setLoading(false);
    },
  });

  if (!data) {
    return "no data";
  }

  return (
    <>
      <div role="presentation" className="align">
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className="dashboard">
            Dashboard
          </Link>
          <Link to="/article" className="dashboard">
            Article
          </Link>
          <span>{id}</span>
        </Breadcrumbs>
      </div>
      <Typography variant="h6" component="h2" sx={{ m: 3, color: "black" }}>
        Article Details
      </Typography>

      <Card>
        <CardContent>
          <Box
            sx={{
              width: "100%",
              height: "300px",
              display: "grid",
              gridTemplateColumns: "repeat(3,1fr)",
              gap: "1rem",
              justifyContent: "center",
              // p: "2rem",
              margin: "auto",
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            {/* image_url */}
            <Box>
              <CardMedia
                component="img"
                height="300px"
                image={data?.articles_by_pk?.image_url}
              />
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "blue",
                }}
              >
                Image Url
              </Typography>
            </Box>
            {/* image_url_1 */}
            <Box>
              <CardMedia
                component="img"
                alt="image 1"
                height="300px"
                image={data?.articles_by_pk?.image_url_1}
              />
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "blue",
                }}
              >
                Image Url 1
              </Typography>
            </Box>
            {/* image_url_2 */}
            <Box>
              <CardMedia
                component="img"
                height="300px"
                alt="image 2"
                image={data?.articles_by_pk?.image_url_2}
              />
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "blue",
                }}
              >
                Image Url 2
              </Typography>
            </Box>
          </Box>

          <CardActions
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(5,1fr)",
              gap: "1rem",
              mt: "2rem",
            }}
          >
            <ListItem>
              <ListItemText
                primary="ID"
                secondary={data.articles_by_pk.id}
              ></ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Article Category"
                secondary={data.articles_by_pk?.article_category?.name}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Article Name Eng"
                secondary={data.articles_by_pk.name}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Article Name MM"
                secondary={data.articles_by_pk?.name_mm}
              ></ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Duration Time"
                secondary={data.articles_by_pk?.duration_time}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Pending"
                secondary={
                  data.articles_by_pk.pending === true ? "Approve" : "Pending"
                }
              ></ListItemText>
            </ListItem>
            {console.log(
              "is traditional ",
              data?.articles_by_pk?.is_traditional
            )}
            <ListItem>
              <ListItemText
                primary="Traditional or Digital"
                secondary={
                  data?.articles_by_pk?.is_traditional === true
                    ? "Traditional"
                    : "Digital"
                }
              ></ListItemText>
            </ListItem>
          </CardActions>
          <Box
            display="grid"
            mx="1rem"
            gap="1rem"
            gridTemplateColumns="repeat(2,1fr)"
          >
            {/* description 1 */}
            <Box>
              <Typography display="flex" justifyContent="center" mt="2rem">
                Description 1
              </Typography>

              <Box sx={{ mt: "1rem", bgcolor: "#f8f9fa" }}>
                <div
                  style={{ color: "#495057", padding: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: data.articles_by_pk.description_1,
                  }}
                ></div>
              </Box>
            </Box>
            <Box>
              <Typography display="flex" justifyContent="center" mt="2rem">
                Description 1 MM
              </Typography>

              <Box sx={{ mt: "1rem", bgcolor: "#f8f9fa" }}>
                <div
                  style={{ color: "#495057", padding: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: data.articles_by_pk.description_1_mm,
                  }}
                ></div>
              </Box>
            </Box>
            {/* description 2 */}
            <Box>
              <Typography display="flex" justifyContent="center" mt="2rem">
                Description 2
              </Typography>

              <Box sx={{ mt: "1rem", bgcolor: "#f8f9fa" }}>
                <div
                  style={{ color: "#495057", padding: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: data.articles_by_pk.description_2,
                  }}
                ></div>
              </Box>
            </Box>
            <Box>
              <Typography display="flex" justifyContent="center" mt="2rem">
                Description 2 MM
              </Typography>

              <Box sx={{ mt: "1rem", bgcolor: "#f8f9fa" }}>
                <div
                  style={{ color: "#495057", padding: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: data.articles_by_pk.description_2_mm,
                  }}
                ></div>
              </Box>
            </Box>
            {/* description 3 */}
            <Box>
              <Typography display="flex" justifyContent="center" mt="2rem">
                Description 3
              </Typography>

              <Box sx={{ mt: "1rem", bgcolor: "#f8f9fa" }}>
                <div
                  style={{ color: "#495057", padding: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: data.articles_by_pk.description_3,
                  }}
                ></div>
              </Box>
            </Box>
            <Box>
              <Typography display="flex" justifyContent="center" mt="2rem">
                Description 3 MM
              </Typography>

              <Box sx={{ mt: "1rem", bgcolor: "#f8f9fa" }}>
                <div
                  style={{ color: "#495057", padding: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: data.articles_by_pk.description_3_mm,
                  }}
                ></div>
              </Box>
            </Box>
          </Box>
        </CardContent>

        <Box display="flex" justifyContent="space-between" m="2rem">
          {/* pending status */}
          <Box>
            {data.articles_by_pk.pending ? (
              <Button
                variant="contained"
                color="warning"
                onClick={() =>
                  pending_status({
                    variables: {
                      id: data.articles_by_pk.id,
                      pending: false,
                    },
                  })
                }
              >
                Approve
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  pending_status({
                    variables: {
                      id: data.articles_by_pk.id,
                      pending: true,
                    },
                  })
                }
              >
                Pending
              </Button>
            )}
          </Box>

          <div>
            <Button
              sx={{ mr: "2rem" }}
              variant="contained"
              onClick={() =>
                navigate(`/update_article/${data.articles_by_pk.id}`)
              }
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpen(true)}
            >
              Remove
            </Button>
          </div>
        </Box>
      </Card>
      <Modal
        keepMounted
        open={open}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button loading={loading} onClick={handleRemove}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default Article;
