import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import DirectionsIcon from "@mui/icons-material/Directions";
import "../../style/App.css";
import {
  Box,
  Breadcrumbs,
  Button,
  TablePagination,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableBody,
  styled,
  TableCell,
  TableRow,
  Modal,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
// import { USERS } from "../../gql/users";
import SideBarContext from "../../context/SideBarContext";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { DELETE_ARTICLE_CAT } from "../../gql/articleCategory";
import { ALL_ARTICLE_CATS } from "../../gql/articleCategory";

const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  borderRadius: 1,
  p: 4,
};

const ArticleCategorys = () => {
  const { setNav } = useContext(SideBarContext);
  const navigate = useNavigate();
  const [isTraditional, setIsTraditional] = useState();
  const [delOpen, setDelOpen] = useState(false);
  const [videoData, setVideoData] = useState();
  const { data: articleCat } = useQuery(ALL_ARTICLE_CATS, {
    variables: {
      articleCatQuery: {
        ...(isTraditional
          ? {
              is_traditional: { _eq: `${isTraditional}` },
            }
          : {}),
      },
    },
    fetchPolicy: "network-only",
  });
  const [delete_video_cat] = useMutation(DELETE_ARTICLE_CAT, {
    onError: (err) => {
      console.log("Delete Error ", err);
      alert("Delete Error");
    },
    onCompleted: (data) => {
      alert("Delete Successfull");
    },
    refetchQueries: [ALL_ARTICLE_CATS],
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      // backgroundColor: "#f1f3f5",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handleRemoveOpen = (data) => {
    setDelOpen(true);
    setVideoData(data);
  };
  const handleRemoveClose = () => setDelOpen(false);
  const handleRemove = async (data) => {
    await delete_video_cat({ variables: { id: videoData?.id } });
    setDelOpen(false);
  };

  if (!articleCat) {
    return;
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        {/* dashboard */}
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard" className="dashboard">
              Dashboard
            </Link>
            <span>Article Category</span>
          </Breadcrumbs>
        </div>

        {/* add */}
        <Button
          variant="contained"
          sx={{
            px: 3,
            py: 1,
          }}
          color="secondary"
          onClick={() => navigate("/create_article_cat")}
        >
          Add
        </Button>
      </div>

      {/* Traditioinal or Digital */}
      {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Choose Traditional or Digital
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={isTraditional}
            onChange={(e) => {
              e.preventDefault();
              setIsTraditional(e.target.value);
            }}
            row
          >
            <FormControlLabel value="" control={<Radio />} label="All" />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Traditional"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Digital"
            />
          </RadioGroup>
        </FormControl>
      </Box> */}

      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            // maxHeight: "70vh",
            Width: "100px",
            border: "1px groove rgba(0,0,0,0.2)",
          }}
        >
          <Table stickyHeader aria-label="sticky table , responsive table">
            <TableHead>
              <StyledTableRow>
                <TableCell
                  style={{
                    minWidth: 100,
                    fontWeight: "bold",
                  }}
                >
                  ID
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Category Name
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Category Name MM
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Traditional Or Digital
                </TableCell>

                <TableCell style={{ minWidth: 100, fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </StyledTableRow>
            </TableHead>
            {articleCat?.article_category?.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {articleCat?.article_category &&
                  articleCat?.article_category.map((row, index) => (
                    <StyledTableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.name_mm}</TableCell>
                      <TableCell>
                        {row?.is_traditional === true
                          ? "Traditional"
                          : "Digital"}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="error"
                          onClick={() => handleRemoveOpen(row)}
                        >
                          <DeleteIcon />
                        </Button>
                        <Button
                          color="warning"
                          onClick={() => navigate(`edit_article_cat/${row.id}`)}
                          // onClick={navigate(`edit_video_cat/${row.id}`)}
                        >
                          <ModeEditIcon />
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
      <Modal
        keepMounted
        open={delOpen}
        onClose={handleRemoveClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleRemoveClose}>
              Cancel
            </Button>
            <Button onClick={handleRemove}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ArticleCategorys;
