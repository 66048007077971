import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Select, makeStyles } from "@material-ui/core";
import DirectionsIcon from "@mui/icons-material/Directions";
import "../../style/App.css";
import * as XLSX from "xlsx-js-style";
import { useApolloClient } from "@apollo/client";

import {
  Box,
  Breadcrumbs,
  Button,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  styled,
  TableRow,
  TableCell,
  TextField,
  FormControl,
  Avatar,
  Typography,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ARTWORK } from "../../gql/artwork";
import SideBarContext from "../../context/SideBarContext";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

const ArtWork = () => {
  const client = useApolloClient();
  const classes = useStyles();
  const { setNav } = useContext(SideBarContext);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [type, setType] = useState("All");
  // const [allData, setAllData] = useState([]);
  const [allDatas, setAllDatas] = useState([]);
  const [status, setStatus] = useState("All");

  // const [loadArtwork, resultArtwork] = useLazyQuery(ARTWORKS);
  // const [loadAnotherApi, resultNotTypeArtwork] = useLazyQuery(ARTWORKNOTTYPE);

  const { loading, error, data } = useQuery(ARTWORK, {
    variables: {
      limit: rowsPerPage,
      offset: offset,
      status: status === "All" ? { _is_null: false } : { _eq: status },
      search: `%${search}%`,
      fk_medium_type: `%${type === "All" ? "" : type}%`,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && data) {
      setAllDatas(data.traditional_art_work);
      setCount(data.traditional_art_work_aggregate.aggregate.count);
    }
  }, [loading, data, rowsPerPage, offset]);

  //////
  // useEffect(() => {
  //   if (type == 0) {
  //     loadAnotherApi({
  //       variables: {
  //         limit: rowsPerPage,
  //         offset: offset,
  //         search: `%${search}%`,
  //       },
  //       fetchPolicy: "network-only",
  //     });
  //   } else {
  //     loadArtwork({
  //       variables: {
  //         limit: rowsPerPage,
  //         offset: offset,
  //         search: `%${search}%`,
  //         fk_medium_type_id: type,
  //       },
  //       fetchPolicy: "network-only",
  //     });
  //   }
  // }, [rowsPerPage, offset, search, type]);

  // useEffect(() => {
  //   if (resultArtwork.data || resultNotTypeArtwork.data) {
  //     const artworkData = resultArtwork.data?.traditional_art_work || [];
  //     const notTypeData =
  //       resultNotTypeArtwork.data?.traditional_art_work || [];

  //     // setAllData(type == 0 ? notTypeData : artworkData);
  //     // setCount(
  //     //   type == 0
  //     //     ? resultNotTypeArtwork.data?.traditional_art_work_aggregate
  //     //         .aggregate.count
  //     //     : resultArtwork.data?.traditional_art_work_aggregate.aggregate.count
  //     // );

  //     const combinedData = type === 0 ? notTypeData : artworkData;

  //     // Sort the combinedData array based on the pending status
  //     const sortedData = combinedData.sort((a, b) => (a.pending === b.pending ? 0 : a.pending ? 1 : -1));

  //     setAllData(sortedData);
  //     setCount(
  //       type === 0
  //         ? resultNotTypeArtwork.data?.traditional_art_work_aggregate
  //           .aggregate.count
  //         : resultArtwork.data?.traditional_art_work_aggregate.aggregate.count
  //     );
  //   }
  // }, [resultArtwork, resultNotTypeArtwork, type]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchValue);
    if (searchValue === "") {
      setSearch("");
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // const exportAllData = () => {
  //   let data = [];

  //   const date = new Date();
  //   const fileName = date.toISOString().slice(0, 10);
  //   const time = formatTime(date);

  //   data.push(["MULA ART GALLERY"]);
  //   data.push(["E1-12,The Secretariat Yangon,Thein Phyu Road, Botahtaung Township, Yangon, Myanmar"]);
  //   data.push([`Date and Time: ${fileName} - ${time}`])
  //   data.push([""]);

  //   const columns = [
  //     "Sr.No",
  //     "ID",
  //     "ArtWork Name",
  //     "ArtWork Year",
  //     "Type",
  //     "Artist Name"
  //   ];

  //   data.push(columns);

  //   for (let index = 0; index < allDatas.length; index++) {
  //     if(allDatas[index] !== undefined) {
  //       const row = [
  //         index + 1,
  //         allDatas[index].id,
  //         allDatas[index].artwork_name,
  //         allDatas[index].artwork_year,
  //         allDatas[index].traditional_art_work_artwork_medium_type.medium_name,
  //         allDatas[index].traditional_art_work_artist.artist_name,
  //       ];

  //       data.push(row);
  //     }
  //   }

  //   if (allDatas.length > 0) {

  //     const wb = XLSX.utils.book_new();
  //     const ws = XLSX.utils.aoa_to_sheet(data);

  //     // Resize column width
  // ws["!cols"] = [
  //   { width: 10 },  // Width for column A
  //   { width: 10 },  // Width for column B
  //   { width: 25 },  // Width for column C
  //   { width: 20 },  // Width for column D
  //   { width: 20 },  // Width for column E
  //   { width: 30 },  // Width for column F
  // ];

  // // Adding styles for "Welcome" row and header row
  //     // ws["A1"].s = { font: { bold: true } }; // Style for "Welcome" row
  //     for (let col = 0; col < columns.length; col++) {
  //       const headerCell = XLSX.utils.encode_cell({ r: 4, c: col });
  //       ws[headerCell].s = { font: { bold: true }, alignment: { horizontal: "center" }  }; // Style for header row
  //     }

  //     // Merging cells A1 to G1 and centering the text
  //   ws["!merges"] = [
  //     { s: { r: 0, c: 0 }, e: { r: 0, c: columns.length - 1 } },
  //     { s: { r: 1, c: 0 }, e: { r: 1, c: columns.length - 1 } },
  //     { s: { r: 2, c: 0 }, e: { r: 2, c: columns.length - 1 } },
  //     { s: { r: 3, c: 0 }, e: { r: 3, c: columns.length - 1 } }
  //   ];

  //   // Apply bold and center styles for the specific rows
  //   for (let i = 0; i < 3; i++) {
  //     const cellAddress = XLSX.utils.encode_cell({ r: i, c: 0 });
  //     ws[cellAddress].s = { font: { bold: true }, alignment: { horizontal: "center" } };
  //   }

  //   for (let i = 5; i < allDatas.length + 5; i++) {
  //     for (let c = 0; c < columns.length; c++) {
  //       const cellAddress = XLSX.utils.encode_cell({ r: i, c: c });
  //       ws[cellAddress].s = { alignment: { horizontal: "center" } };
  //     }
  //   }

  //     XLSX.utils.book_append_sheet(wb, ws, "Receipt Data");
  //     XLSX.writeFile(wb, `ArtworkList_${fileName}.xlsx`);
  //   } else {
  //     alert("No Data to export!");
  //   }

  // }

  // const exportAllData = async() => {

  //   let data = [];

  //   const date = new Date();
  //   const fileName = date.toISOString().slice(0, 10);
  //   const time = formatTime(date);

  //   data.push(["MULA ART GALLERY"]);
  //   data.push([
  //     "E1-12,The Secretariat Yangon,Thein Phyu Road, Botahtaung Township, Yangon, Myanmar",
  //   ]);
  //   data.push([`Date and Time: ${fileName} - ${time}`]);
  //   data.push([""]);

  //   const columns = [
  //     "Sr.No",
  //     "ID",
  //     "ArtWork Name",
  //     "ArtWork Year",
  //     "Type",
  //     "Artist Name",
  //   ];

  //   data.push(columns);

  //   for (let index = 0; index < allDatas.length; index++) {
  //     if (allDatas[index] !== undefined) {
  //       // Add a check to include only the selected status

  //         const row = [
  //           index + 1,
  //           allDatas[index].id,
  //           allDatas[index].artwork_name,
  //           allDatas[index].artwork_year,
  //           allDatas[index].traditional_art_work_artwork_medium_type.medium_name,
  //           allDatas[index].traditional_art_work_artist.artist_name,
  //         ];

  //         data.push(row);

  //     }
  //   }

  //   if (data.length > 4) {
  //     const wb = XLSX.utils.book_new();
  //     const ws = XLSX.utils.aoa_to_sheet(data);

  //     // Resize column width
  //     ws["!cols"] = [
  //       { width: 10 },
  //       { width: 10 },
  //       { width: 25 },
  //       { width: 20 },
  //       { width: 20 },
  //       { width: 30 },
  //     ];

  //     // Adding styles for "Welcome" row and header row
  //     for (let col = 0; col < columns.length; col++) {
  //       const headerCell = XLSX.utils.encode_cell({ r: 4, c: col });
  //       ws[headerCell].s = {
  //         font: { bold: true },
  //         alignment: { horizontal: "center" },
  //       }; // Style for header row
  //     }

  //     // Merging cells A1 to G1 and centering the text
  //     ws["!merges"] = [
  //       { s: { r: 0, c: 0 }, e: { r: 0, c: columns.length - 1 } },
  //       { s: { r: 1, c: 0 }, e: { r: 1, c: columns.length - 1 } },
  //       { s: { r: 2, c: 0 }, e: { r: 2, c: columns.length - 1 } },
  //       { s: { r: 3, c: 0 }, e: { r: 3, c: columns.length - 1 } },
  //     ];

  //     // Apply bold and center styles for the specific rows
  //     for (let i = 0; i < 3; i++) {
  //       const cellAddress = XLSX.utils.encode_cell({ r: i, c: 0 });
  //       ws[cellAddress].s = { font: { bold: true }, alignment: { horizontal: "center" } };
  //     }

  //     for (let i = 5; i < data.length; i++) {
  //       for (let c = 0; c < columns.length; c++) {
  //         const cellAddress = XLSX.utils.encode_cell({ r: i, c: c });
  //         ws[cellAddress].s = { alignment: { horizontal: "center" } };
  //       }
  //     }

  //     XLSX.utils.book_append_sheet(wb, ws, "Receipt Data");
  //     XLSX.writeFile(wb, `ArtworkList_${fileName}.xlsx`);
  //   } else {
  //     alert("No Data to export!");
  //   }
  // };

  const exportAllData = async () => {
    try {
      // Fetch all data based on the current filter criteria
      const { data: filteredData } = await client.query({
        query: ARTWORK,
        variables: {
          limit: null,
          offset: 0,
          status: status === "All" ? { _is_null: false } : { _eq: status },
          search: `%${search}%`,
          fk_medium_type: `%${type === "All" ? "" : type}%`,
        },
      });

      if (!filteredData || !filteredData.traditional_art_work) {
        throw new Error("No data to export");
      }

      let data = [];

      const date = new Date();
      const fileName = date.toISOString().slice(0, 10);
      const time = formatTime(date);

      data.push(["MULA ART GALLERY"]);
      data.push([
        "E1-12,The Secretariat Yangon,Thein Phyu Road, Botahtaung Township, Yangon, Myanmar",
      ]);
      data.push([`Date and Time: ${fileName} - ${time}`]);
      data.push([""]);

      const columns = [
        "Sr.No",
        "ID",
        "ArtWork Name",
        "ArtWork Year",
        "Type",
        "Artist Name",
        "Status",
      ];

      data.push(columns);

      filteredData.traditional_art_work.forEach((row, index) => {
        if (row) {
          const rowData = [
            index + 1,
            row.id,
            row.artwork_name,
            row.artwork_year,
            row.traditional_art_work_artwork_medium_type.medium_name,
            row.traditional_art_work_artist.artist_name,
            row.pending === true ? "Pending" : "Approved",
          ];

          data.push(rowData);
        }
      });

      if (data.length > 4) {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);

        // Resize column width
        ws["!cols"] = [
          { width: 10 },
          { width: 10 },
          { width: 25 },
          { width: 20 },
          { width: 20 },
          { width: 30 },
        ];

        // Adding styles for "Welcome" row and header row
        for (let col = 0; col < columns.length; col++) {
          const headerCell = XLSX.utils.encode_cell({ r: 4, c: col });
          ws[headerCell].s = {
            font: { bold: true },
            alignment: { horizontal: "center" },
          }; // Style for header row
        }

        // Merging cells A1 to G1 and centering the text
        ws["!merges"] = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: columns.length - 1 } },
          { s: { r: 1, c: 0 }, e: { r: 1, c: columns.length - 1 } },
          { s: { r: 2, c: 0 }, e: { r: 2, c: columns.length - 1 } },
          { s: { r: 3, c: 0 }, e: { r: 3, c: columns.length - 1 } },
        ];

        // Apply bold and center styles for the specific rows
        for (let i = 0; i < 3; i++) {
          const cellAddress = XLSX.utils.encode_cell({ r: i, c: 0 });
          ws[cellAddress].s = {
            font: { bold: true },
            alignment: { horizontal: "center" },
          };
        }

        for (let i = 5; i < data.length; i++) {
          for (let c = 0; c < columns.length; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: i, c: c });
            ws[cellAddress].s = { alignment: { horizontal: "center" } };
          }
        }

        XLSX.utils.book_append_sheet(wb, ws, "Receipt Data");
        XLSX.writeFile(wb, `ArtworkList_${fileName}.xlsx`);
      } else {
        alert("No Data to export!");
      }
    } catch (error) {
      console.error("Error exporting data:", error.message);
      alert("Error exporting data. Please try again.");
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        {/* dashboard */}
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard" className="dashboard">
              Dashboard
            </Link>
            <span>ArtWork</span>
          </Breadcrumbs>
        </div>
        {/* search */}
        <div>
          <form onSubmit={handleSearch}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
              }}
            >
              {/* Search Box */}

              <InputBase
                id="search-by-phone"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Artwork Name"
                type="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                //   color="warning"
                sx={{ p: "10px" }}
                aria-label="directions"
                type="submit"
                value={search}
                onClick={handleSearch}
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </form>
        </div>
        {/* artwork type */}

        <FormControl sx={{ width: "150px" }}>
          <InputLabel
            id="main_type"
            sx={{
              my: -2,
              fontSize: 20,
            }}
          >
            Artwork Type
          </InputLabel>
          <Select
            labelId="fk_medium_type"
            variant="filled"
            autoWidth
            label="Artwork Type"
            value={type}
            defaultValue=""
            onChange={(e) => {
              e.preventDefault();
              setType(e.target.value);
              setSearch("");
              setSearchValue("");
            }}
          >
            <MenuItem value="" disabled>
              Type
            </MenuItem>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Oil Painting">Oil Painting</MenuItem>
            <MenuItem value="Watercolor">Watercolor</MenuItem>
            <MenuItem value="Acrylic">Acylic</MenuItem>
            <MenuItem value="Fiber glass">Fiber Glass</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ width: "150px" }}>
          <InputLabel
            id="main_type"
            sx={{
              my: -2,
              fontSize: 20,
            }}
          >
            Status
          </InputLabel>
          <Select
            labelId="fk_medium_type"
            variant="filled"
            autoWidth
            label="Artwork Type"
            value={status}
            defaultValue=""
            onChange={(e) => {
              e.preventDefault();
              setStatus(e.target.value);
              setSearch("");
              setSearchValue("");
            }}
          >
            <MenuItem value="" disabled>
              Status
            </MenuItem>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="true">Pending</MenuItem>
            <MenuItem value="false">Approved</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          sx={{
            px: 3,
            py: 1,
          }}
          color="secondary"
          onClick={exportAllData}
        >
          Export Data
        </Button>
        {/* add */}
        <Button
          variant="contained"
          sx={{
            px: 3,
            py: 1,
          }}
          color="secondary"
          onClick={() => navigate("/create_artWork")}
        >
          Add
        </Button>
      </div>
      <Typography sx={{ padding: "1rem" }}>Total Artworks - {count}</Typography>
      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            // maxHeight: "70vh",
            Width: "100px",
            border: "1px groove rgba(0,0,0,0.2)",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table , responsive table"
            className={classes.table}
          >
            <TableHead>
              <StyledTableRow>
                <TableCell
                  style={{
                    minWidth: 100,
                    fontWeight: "bold",
                  }}
                >
                  ID
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Artwork Image
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Artwork Name
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Artwork Year
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Artwork Type
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Created At
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Updated At
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Status
                </TableCell>
                <TableCell style={{ minWidth: 100, fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </StyledTableRow>
            </TableHead>
            {allDatas.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Artworks Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {allDatas &&
                  allDatas.map((row, index) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      key={index}
                      tabIndex={-1}
                    >
                      {row.current_price === row.update_price ? (
                        <TableCell>{row.id}</TableCell>
                      ) : (
                        <TableCell sx={{ color: "red" }}>{row.id}</TableCell>
                      )}
                      <TableCell>
                        <Avatar
                          width="52px"
                          height="52px"
                          src={row.artwork_image_url}
                        ></Avatar>
                      </TableCell>
                      {row.current_price === row.update_price ? (
                        <TableCell>
                          {row.artwork_name.substring(0, 16)}
                        </TableCell>
                      ) : (
                        <TableCell sx={{ color: "red" }}>
                          {row.artwork_name.substring(0, 10)}
                        </TableCell>
                      )}
                      {row.current_price === row.update_price ? (
                        <TableCell>{row.artwork_year}</TableCell>
                      ) : (
                        <TableCell sx={{ color: "red" }}>
                          {row.artwork_year}
                        </TableCell>
                      )}
                      {row.current_price === row.update_price ? (
                        <TableCell>
                          {
                            row.traditional_art_work_artwork_medium_type
                              ?.medium_name
                          }
                        </TableCell>
                      ) : (
                        <TableCell sx={{ color: "red" }}>
                          {
                            row.traditional_art_work_artwork_medium_type
                              ?.medium_name
                          }
                        </TableCell>
                      )}
                      {row.current_price === row.update_price ? (
                        <TableCell>{row.created_at.substring(0, 10)}</TableCell>
                      ) : (
                        <TableCell sx={{ color: "red" }}>
                          {row.created_at.substring(0, 10)}
                        </TableCell>
                      )}
                      {row.current_price === row.update_price ? (
                        <TableCell>{row.updated_at.substring(0, 10)}</TableCell>
                      ) : (
                        <TableCell sx={{ color: "red" }}>
                          {row.updated_at.substring(0, 10)}
                        </TableCell>
                      )}
                      <TableCell>
                        {row.pending ? (
                          <Typography
                            sx={{
                              backgroundColor: "orange",
                              px: 2,
                              py: 1,
                              color: "#fff",
                              borderRadius: "3px",
                            }}
                          >
                            Pending
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              backgroundColor: "green",
                              px: 2,
                              py: 1,
                              color: "#fff",
                              borderRadius: "3px",
                            }}
                          >
                            Approve
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          sx={{ color: "white", p: 1, mr: 1 }}
                          onClick={() =>
                            window.open(`/art_work/${row?.id}`, "_blank")
                          }
                        >
                          Detail
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ color: "black" }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
};

export default ArtWork;

function formatTime(dateTimeString) {
  const date = new Date(dateTimeString);
  return date.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
}
