import { gql } from "@apollo/client";

export const ALL_SERIES = gql`
query MyQuery ($limit:Int!, $offset : Int!, $search: String!) {
    art_series(order_by: {updated_at: desc}, where: {series_name: {_ilike: $search}}, limit: $limit, offset: $offset) {
      created_at
      fk_artist_id
      id
      series_description
      series_description_mm
      series_name
      series_name_mm
      series_thumbnail_url_id
      updated_at
    }
    art_series_aggregate {
      aggregate {
        count
      }
    }
  }

`;