import { gql } from "@apollo/client";
//get all article category
export const ALL_ARTICLE_CATS = gql`
  query aa($articleCatQuery: article_category_bool_exp!) {
    article_category(order_by: { updated_at: desc }, where: $articleCatQuery) {
      id
      name
      name_mm
      is_traditional
    }
  }
`;

//article cat by pk
export const ARTICLE_CAT_BY_PK = gql`
  query aa($id: Int!) {
    article_category_by_pk(id: $id) {
      id
      name
      name_mm
      is_traditional
    }
  }
`;

//add article cat
export const ADD_ARTICLE_CAT = gql`
  mutation ($name: String!, $name_mm: String, $is_traditional: Boolean!) {
    insert_article_category_one(
      object: {
        name: $name
        name_mm: $name_mm
        is_traditional: $is_traditional
      }
    ) {
      id
    }
  }
`;

//edit article cat
export const EDIT_ARTICLE_CAT = gql`
  mutation MyMutation(
    $id: Int!
    $name: String!
    $name_mm: String
    $is_traditional: Boolean!
  ) {
    update_article_category_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, name_mm: $name_mm, is_traditional: $is_traditional }
    ) {
      name
      id
    }
  }
`;

//delete article cat
export const DELETE_ARTICLE_CAT = gql`
  mutation delete_article_category($id: Int!) {
    delete_article_category_by_pk(id: $id) {
      id
    }
  }
`;
