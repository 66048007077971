import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CardActionArea,
  CardContent,
  FormControl,
  InputLabel,
  Card,
  Breadcrumbs,
  Typography,
  Button,
  CardActions,
  Select,
  Box,
  MenuItem,
  useScrollTrigger,
  FormLabel,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useDebugValue, useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  ADD_RESELLER,
  ALL_RESELLER,
  RESELLER_ID,
  UPDATE_RESELLER,
  USERID,
} from "../../gql/reseller";
import RichTextEditor from "react-rte";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import {
  ALL_VIDEO_CATS,
  EDIT_VIDEO_CAT,
  VIDEO_CAT_BY_PK,
} from "../../gql/videoCategory";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const UpdateVideoCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [isTraditional, setIsTraditional] = useState(true);
  const [values, setValues] = useState({});
  const { data: videoCatPk } = useQuery(VIDEO_CAT_BY_PK, {
    variables: { id: id },
  });
  useEffect(() => {
    if (videoCatPk) {
      setValues({
        id: videoCatPk?.video_category_by_pk?.id,
        name: videoCatPk?.video_category_by_pk?.name,
        name_mm: videoCatPk?.video_category_by_pk?.name_mm,
        is_traditional: videoCatPk?.video_category_by_pk?.is_traditional,
      });
      setIsTraditional(videoCatPk?.video_category_by_pk?.is_traditional);
    }
  }, [videoCatPk]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [UpdateVideoCategory] = useMutation(EDIT_VIDEO_CAT, {
    onError: (err) => {
      alert("Error on Server");
      console.log("update Error", err);
    },
    onCompleted: (result) => {
      setValues({});
      alert("Video Category has been updated");
      navigate("/video_cat");
    },
    refetchQueries: [ALL_VIDEO_CATS],
  });
  console.log("values ", values);
  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    await UpdateVideoCategory({
      variables: { ...values, is_traditional: isTraditional },
    });
  };

  return (
    <>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          {/* dashboard */}
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              {/* <Link to="/" className="dashboard"> */}
              <Typography variant="h6">
                Mula Dashboard (Video Category)
              </Typography>

              {/* </Link> */}
              {/* <span>ArtWork</span> */}
            </Breadcrumbs>
            <Typography>Main / Video Category</Typography>
          </div>
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </div>
        <CardContent sx={{ p: "2rem" }}>
          <Box sx={{ my: "1rem" }}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Choose Traditional or Digital
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isTraditional}
                onChange={(e) => setIsTraditional(e.target.value)}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Traditional"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Digital"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "grid",
              rowGap: "2rem",
            }}
          >
            {/* name  */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Category Name</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="cat_name"
                placeholder="Enter Category Name"
                value={values.name}
                onChange={handleChange("name")}
              />
            </FormControl>
            {/* name mm  */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>
                Category Name MM
              </FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="cat_name"
                placeholder="Enter Category Name MM"
                value={values.name_mm}
                onChange={handleChange("name_mm")}
              />
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-end" my="2rem">
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleUpdate}
            >
              Update
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
export default UpdateVideoCategory;
