import { useEffect, useState } from "react";

import {
  Box,
  Breadcrumbs,
  TableRow,
  TablePagination,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
  Table,
  styled,
  TableCell,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useLazyQuery } from "@apollo/client";
import { ALL_FEEDBACK } from "../../gql/feedBack";

const FeedBacks = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [feedBacks, setFeedBacks] = useState();
  const [loadFeedBack, resultFeeBack] = useLazyQuery(ALL_FEEDBACK);

  useEffect(() => {
    loadFeedBack({
      variables: {
        limit: rowsPerPage,
        offset: offset,
      },
      fetchPolicy: "network-only",
    });
  }, [loadFeedBack, rowsPerPage, offset]);

  useEffect(() => {
    if (resultFeeBack.data) {
      setFeedBacks(resultFeeBack.data.feedback);
      setCount(resultFeeBack.data?.feedback_aggregate?.aggregate.count);
    }
  }, [resultFeeBack]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchValue);
    if (searchValue === "") {
      loadFeedBack({
        variables: {
          limit: rowsPerPage,
          offset: offset,
          search: `%${search}%`,
        },
        fetchPolicy: "network-only",
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  if (!feedBacks) {
    return;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        {/* dashboard */}
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard" className="dashboard">
              Dashboard
            </Link>
            <span>FeedBack</span>
          </Breadcrumbs>
        </div>
        {/* search */}
        {/* <div>
          <form onSubmit={handleSearch}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 350,
              }}
            >
              {/* Search Box */}
        {/* <InputBase
                id="search-by-name"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search By Video Name"
                type="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                //   color="warning"
                sx={{ p: "10px" }}
                aria-label="directions"
                type="submit"
                value={search}
                onClick={handleSearch}
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </form> 
        </div> */}
      </div>

      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            // maxHeight: "70vh",
            Width: "100px",
            border: "1px groove rgba(0,0,0,0.2)",
          }}
        >
          <Table stickyHeader aria-label="sticky table , responsive table">
            <TableHead>
              <StyledTableRow>
                <TableCell
                  style={{
                    minWidth: 100,
                    fontWeight: "bold",
                  }}
                >
                  ID
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Message
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Subject
                </TableCell>
              </StyledTableRow>
            </TableHead>

            {feedBacks.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No feedBacks Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {feedBacks &&
                  feedBacks.map((row, index) => (
                    <StyledTableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell>{index + 1}</TableCell>

                      <TableCell>{row?.message}</TableCell>
                      <TableCell>{row?.subject}</TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ color: "black" }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default FeedBacks;
