import { gql } from "@apollo/client";

//all video cats
export const ALL_VIDEO_CATS = gql`
  query aa {
    video_category(order_by: { updated_at: desc }) {
      id
      name
      name_mm
      is_traditional
    }
  }
`;

//video cat by pk
export const VIDEO_CAT_BY_PK = gql`
  query aa($id: Int!) {
    video_category_by_pk(id: $id) {
      id
      name
      name_mm
      is_traditional
    }
  }
`;
//create video category
export const ADD_VIDEO_CAT = gql`
  mutation aa($name: String!, $name_mm: String, $is_traditional: Boolean!) {
    insert_video_category(
      objects: {
        name: $name
        name_mm: $name_mm
        is_traditional: $is_traditional
      }
    ) {
      returning {
        name
        id
      }
    }
  }
`;

//edit video cat by pk

export const EDIT_VIDEO_CAT = gql`
  mutation MyMutation(
    $id: Int!
    $name: String!
    $name_mm: String
    $is_traditional: Boolean!
  ) {
    update_video_category_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, name_mm: $name_mm, is_traditional: $is_traditional }
    ) {
      name
      id
    }
  }
`;

//delete video cat
export const DELETE_VIDEO_CAT = gql`
  mutation delete_video_category($id: Int!) {
    delete_video_category_by_pk(id: $id) {
      id
    }
  }
`;
