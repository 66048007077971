import {
  Button,
  Typography,
  Box,
  CardContent,
  CardMedia,
  Card,
  FormControl,
  TextField,
  Breadcrumbs,
  Checkbox,
  FormControlLabel,
  Modal,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  TextareaAutosize,
  FormLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFetcher, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { DELETE_IMAGE, IMAGE_UPLOAD } from "../../gql/image";
import RichTextEditor from "react-rte";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  ARTIST_NAME,
  DIMENSIONS,
  ARTWORK_TYPE,
  OWNERSHIP,
  ART_SERIES,
  ADD_ARTWORK,
  UPDATE_ARTWORK,
  ARTWORKS,
  ADD_ART_SERIES,
  ART_SERIES_BY_ARTWORK_ID,
  DELETE_ART_SERIES,
} from "../../gql/artwork";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import imageService from "../../services/image";
import { ARTWORK_ID } from "../../gql/artwork";
import { Air } from "@mui/icons-material";
import {
  ADD_DIGITAL_ART_SERIES,
  DELETE_DIGITAL_ART_SERIES,
  DIGITAL_ARTIST_NAME,
  DIGITAL_ARTWORK_ID,
  DIGITAL_ART_SERIES,
  DIGITAL_ART_SERIES_BY_PK,
  UPDATE_DIGITAL_ARTWORK,
} from "../../gql/digitalArtwork";

const imageType = ["image/jpeg", "image/png"];

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const UpdateDigitalArtwork = () => {
  console.log("objectogkkkkkk");
  const navigate = useNavigate();
  const { id } = useParams();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [isImageChange, setImageChange] = useState(false);
  const [oldImageName, setOldImageName] = useState();

  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  const [textValueMM, setTextValueMM] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [checkedItems, setCheckedItems] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const { data: ownershipData } = useQuery(OWNERSHIP);
  const { data: nameData } = useQuery(DIGITAL_ARTIST_NAME);
  const [loadArtwork, resultArtwork] = useLazyQuery(DIGITAL_ARTWORK_ID);

  const [loadSeriesData, { data: seriesData }] =
    useLazyQuery(DIGITAL_ART_SERIES);

  const [seriesByArtworkItems, setSeriesByArtworkItems] = useState();
  const [loadSeriesDataByArtwork, resultSeriesDataByArtwork] = useLazyQuery(
    DIGITAL_ART_SERIES_BY_PK
  );

  useEffect(() => {
    loadArtwork({ variables: { artWorkId: id } });
  }, [loadArtwork]);

  useEffect(() => {
    if (resultArtwork.data) {
      setValues({
        id: resultArtwork?.data.digital_art_work_by_pk.id ?? "",
        artwork_image_url:
          resultArtwork.data.digital_art_work_by_pk.artwork_image_url ?? "",
        artwork_name:
          resultArtwork.data.digital_art_work_by_pk.artwork_name ?? "",
        artwork_name_mm:
          resultArtwork.data.digital_art_work_by_pk.artwork_name_mm ?? "",
        description:
          resultArtwork.data.digital_art_work_by_pk.description ?? "",
        description_mm:
          resultArtwork.data.digital_art_work_by_pk.description_mm ?? "",
        artwork_year:
          resultArtwork.data.digital_art_work_by_pk.artwork_year ?? "",
        current_price: Number(
          resultArtwork.data.digital_art_work_by_pk.current_price ?? ""
        ),
        disabled: false,
        fk_artist_id:
          resultArtwork.data.digital_art_work_by_pk.fk_artist_id ?? "",
        fk_ownership_id:
          resultArtwork.data.digital_art_work_by_pk.fk_ownership_id ?? "",
        pending: true,
        update_price: Number(
          resultArtwork.data.digital_art_work_by_pk.update_price ?? ""
        ),
      });

      setImagePreview(
        resultArtwork.data.digital_art_work_by_pk.artwork_image_url
      );
      setTextValue(
        RichTextEditor.createValueFromString(
          resultArtwork.data.digital_art_work_by_pk.description,
          "html"
        )
      );
      setTextValueMM(
        RichTextEditor.createValueFromString(
          resultArtwork.data.digital_art_work_by_pk.description_mm,
          "html"
        )
      );
      let image = resultArtwork.data.digital_art_work_by_pk.artwork_image_url;
      setOldImageName(
        image.substring(image.lastIndexOf("/") + 1, image.lenght)
      );
    }
  }, [resultArtwork]);

  useEffect(() => {
    // if (resultArtwork.data) {
    //   loadSeriesData({
    //     variables: {
    //       fk_artist_id:
    //         resultArtwork.data.digital_art_work_by_pk
    //           .traditional_art_work_artist.id,
    //     },
    //   });
    // }

    if (resultArtwork.data) {
      loadSeriesDataByArtwork({
        variables: {
          artwork_id: resultArtwork?.data.digital_art_work_by_pk.id,
        },
        fetchPolicy: "network-only",
      });
    }
  }, [loadSeriesDataByArtwork, resultArtwork]);

  useEffect(() => {
    if (resultSeriesDataByArtwork.data) {
      setSeriesByArtworkItems(
        resultSeriesDataByArtwork.data.digital_artist_art_series
      );
      setIsChecked(true);
    }
  }, [resultSeriesDataByArtwork]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  //for image upload
  const [getImageUrl] = useMutation(IMAGE_UPLOAD, {
    onError: (error) => {
      alert("Error on Server");
    },
    onCompleted: (result) => {
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setImageChange(true);
      setValues({
        ...values,
        artwork_image_url: `https://axra.sgp1.digitaloceanspaces.com/Mula/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const chooseImage = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      if (!imageType.includes(image.type)) {
        // setErrors({
        //   ...error,
        //   artist_profile_image_url: "Please Select image (png,jpeg)",
        // });
        return;
      }
      if (image.size > 10485760) {
        // setErrors({
        //   ...error,
        //   artist_profile_image_url: "Image size must be smaller than 10MB",
        // });
        return;
      }
      setImageFile(image);
      setImagePreview(URL.createObjectURL(image));
      getImageUrl({ variables: { contentType: "image/*" } });
    }
  };

  const [add_art_series] = useMutation(ADD_DIGITAL_ART_SERIES, {
    onError: (err) => {
      setLoading(false);
      alert("Error on Server");
    },
  });

  const [delete_art_series] = useMutation(DELETE_DIGITAL_ART_SERIES, {
    onError: (err) => {
      console.log("Delete Error ", err);
      setLoading(false);
    },
    onCompleted: (result) => {
      console.log("deleted ", result);
    },
  });

  const [update_artwork] = useMutation(UPDATE_DIGITAL_ARTWORK, {
    onError: (err) => {
      alert("Error on server");
      console.log("Error ", err);
      setLoading(false);
    },
    onCompleted: (result) => {
      if (resultArtwork.data) {
        {
          console.log(
            "fk digital artwork id",
            resultArtwork.data.digital_art_work_by_pk.id
          );
        }
        delete_art_series({
          variables: {
            artwork_id: resultArtwork?.data.digital_art_work_by_pk.id,
          },
        });
      }
      checkedItems.map((checkedItem) => {
        add_art_series({
          variables: {
            fk_digital_artseries_id: checkedItem,
            fk_digital_artwork_id: result.update_digital_art_work_by_pk.id,
          },
        });
        // setSeriesByArtworkItems("");
      });

      setLoading(false);
      setTextValue(RichTextEditor.createEmptyValue());
      setValues({});
      alert("New Artwork has been updated");
      navigate("/digital_artwork");
    },
    refetchQueries: [ARTWORKS],
  });

  const onChange = (value) => {
    setTextValue(value);
    setValues({ ...values, description: value.toString("html") });
  };
  const onChangeMM = (value) => {
    setTextValueMM(value);
    setValues({ ...values, description_mm: value.toString("html") });
  };

  const [delete_image] = useMutation(DELETE_IMAGE, {
    onError: (err) => {
      // alert("Error on Server");
      setLoading(false);
    },
  });

  const handleUpdate = async () => {
    setLoading(true);
    try {
      if (isImageChange) {
        await imageService.uploadImage(imageFileUrl, imageFile);
        await delete_image({ variables: { image_name: oldImageName } });
      }

      await update_artwork({
        variables: {
          ...values,
          pending: true,
          disabled: false,
          fk_artist_id: values.fk_artist_id,
        },
      });
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }
    setCheckedItems(newCheckedItems);
  };

  const changeArtistName = async (e) => {
    e.preventDefault();
    await loadSeriesData({ variables: { artist_id: e.target.value } });
    setSeriesByArtworkItems("");
    setValues({ ...values, fk_artist_id: e.target.value });
  };

  if (
    !ownershipData ||
    !nameData ||
    !resultArtwork ||
    !resultSeriesDataByArtwork
  ) {
    return "no data";
  }

  return (
    <>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          {/* dashboard */}
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              {/* <Link to="/" className="dashboard"> */}
              <Typography variant="h6">Mula Dashboard (Artwork)</Typography>

              {/* </Link> */}
              {/* <span>ArtWork</span> */}
            </Breadcrumbs>
            <Typography>Main / Artwork</Typography>
          </div>
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </div>
        <CardContent sx={{ p: 3 }} elevation={4}>
          <Box className="image">
            <CardMedia
              component="img"
              height="200px"
              image={imagePreview}
              // sx={{ my: 2 }}
            />
          </Box>
          <Box display="flex" justifyContent="center" mb="1rem">
            {/* image */}
            <FormControl
              sx={{
                maxWidth: "20%",
              }}
              className="photoCamera"
            >
              <Typography
                sx={{
                  mb: 1,
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Rendered size must be 1920 * 1080 px and Aspect ratio must be
                16:9Cli
              </Typography>
              <Button
                variant="contained"
                component="label"
                size="large"
                // sx={{ py: "0.5rem" }}
              >
                {/* <PhotoCamera /> */}
                <CloudUploadSharpIcon />
                <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                <input
                  hidden
                  onChange={chooseImage}
                  accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                  type="file"
                  error={errors["artwork_image_url"]}
                />
              </Button>
              <FormHelperText error>
                {errors["artwork_image_url"]}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              px: "0.5rem",
              rowGap: "1rem",
              columnGap: "5rem",
            }}
          >
            {/* Artwork Name */}
            <FormControl>
              <FormLabel style={{ fontWeight: "bold" }}>
                Artwork Name (Eng)
              </FormLabel>
              <TextField
                InputProps={{ sx: { height: 50 } }}
                variant="outlined"
                id="artwork_name"
                //placeholder="Artwork Name"
                value={values.artwork_name}
                onChange={handleChange("artwork_name")}
                // error={error.artwork_name ? true : false}
                // helperText={error.artwork_name}
              />
            </FormControl>

            {/* Artwork Name */}
            <FormControl>
              <FormLabel style={{ fontWeight: "bold" }}>
                Artwork Name (MM)
              </FormLabel>
              <TextField
                InputProps={{ sx: { height: 50 } }}
                variant="outlined"
                id="artwork_name_mm"
                placeholder="Artwork Name MM"
                value={values.artwork_name_mm}
                onChange={handleChange("artwork_name_mm")}
                // error={error.artwork_name_mm ? true : false}
                // helperText={error.artwork_name_mm}
              />
            </FormControl>
            {/* artwork_year */}
            <FormControl>
              <FormLabel style={{ fontWeight: "bold" }}>Artwork Year</FormLabel>
              <TextField
                InputProps={{ sx: { height: 50 } }}
                type="number"
                variant="outlined"
                id="artwork_year"
                //label="artwork_year"
                value={values.artwork_year}
                onChange={handleChange("artwork_year")}
                // error={error.artwork_year ? true : false}
                // helperText={error.artwork_year}
              />
            </FormControl>
            {/* current_price */}
            <FormControl>
              <FormLabel style={{ fontWeight: "bold" }}>
                Current Price
              </FormLabel>
              <TextField
                InputProps={{ sx: { height: 50 } }}
                type="number"
                variant="outlined"
                id="current_price"
                //label="current_price"
                value={values.current_price}
                onChange={handleChange("current_price")}
                // error={error.current_price ? true : false}
                // helperText={error.current_price}
              />
            </FormControl>
            {/* update_price */}
            <FormControl>
              <FormLabel style={{ fontWeight: "bold" }}>Update Price</FormLabel>
              <TextField
                InputProps={{ sx: { height: 50 } }}
                type="number"
                variant="outlined"
                id="update_price"
                //label="update_price"
                value={values.update_price}
                onChange={handleChange("update_price")}
                // error={error.update_price ? true : false}
                // helperText={error.update_price}
              />
            </FormControl>

            {/* artist */}

            {values.fk_artist_id && (
              <FormControl>
                <FormLabel style={{ fontWeight: "bold" }}>Artist</FormLabel>
                <Select
                  style={{ height: "50px" }}
                  labelId="artist"
                  placeholder="artist"
                  variant="outlined"
                  value={values.fk_artist_id}
                  onChange={changeArtistName}
                >
                  {nameData
                    ? nameData.artist.map((ast) => (
                        <MenuItem key={ast.id} value={ast.id}>
                          {ast.artist_name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            )}

            {/* ownership */}

            {values.fk_ownership_id && (
              <FormControl>
                <FormLabel style={{ fontWeight: "bold" }}>Ownership</FormLabel>
                <Select
                  style={{ height: "50px" }}
                  labelId="fk_ownership_id"
                  variant="outlined"
                  defaultValue=""
                  value={values.fk_ownership_id}
                  onChange={handleChange("fk_ownership_id")}
                >
                  <MenuItem value="" disabled>
                    Value
                  </MenuItem>

                  {Array.isArray(ownershipData.users)
                    ? ownershipData.users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.fullname}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            )}

            {/* art_series */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                flexWrap: "wrap",
              }}
            >
              {seriesByArtworkItems &&
                seriesByArtworkItems?.map((series) => (
                  <FormControlLabel
                    control={
                      <Checkbox name={series.digtal_art_sery.series_name} />
                    }
                    checked={isChecked}
                    label={series.digtal_art_sery.series_name}
                    onChange={() =>
                      handleCheckboxChange(series.artist_art_series_art_sery.id)
                    }
                  />
                ))}
              {/* {Array.isArray(seriesItems) &&
              seriesItems.map((series) => (
                <FormControlLabel
                  control={<Checkbox name={series.series_name} />}
                  label={series.series_name}
                  onChange={() => handleCheckboxChange(series.id)}
                />
              ))} */}

              {seriesData &&
                seriesData.digtal_art_series.map((ser, index) => (
                  <FormControlLabel
                    control={<Checkbox name={ser.series_name} />}
                    label={ser.series_name}
                    onChange={() => handleCheckboxChange(ser.id)}
                  />
                ))}
            </Box>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            rowGap="1rem"
            columnGap="5rem"
            px="0.5rem"
            py="2rem"
          >
            {/* description */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChange}
                value={textValue}
                toolbarConfig={toolbarConfig}
              />
              {/* {error.description && (
                <FormHelperText error> {error.description}</FormHelperText>
              )} */}
            </Box>

            {/* description_mm */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description MM
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChangeMM}
                value={textValueMM}
                toolbarConfig={toolbarConfig}
              />
              {/* {error.description_mm && (
                <FormHelperText error> {error.description_mm}</FormHelperText>
              )} */}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end", m: "2rem" }}>
            <LoadingButton
              variant="contained"
              onClick={handleUpdate}
              loading={loading}
            >
              Update
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default UpdateDigitalArtwork;
