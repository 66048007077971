import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@material-ui/core";
import DirectionsIcon from "@mui/icons-material/Directions";
import "../../style/App.css";
import {
  Box,
  Breadcrumbs,
  Button,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  tableCellClasses,
  styled,
  TableRow,
  TableCell,
  TextField,
  FormControl,
  Avatar,
  Typography,
} from "@mui/material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ARTWORKS } from "../../gql/artwork";
import SideBarContext from "../../context/SideBarContext";
import { All_DIGITAL_ARTWORKS } from "../../gql/digitalArtwork";
const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});
const DigitalArtworks = () => {
  const classes = useStyles();
  const { setNav } = useContext(SideBarContext);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [data, setData] = useState();
  const [loadDigitalArtwork, resultData] = useLazyQuery(All_DIGITAL_ARTWORKS);

  useEffect(() => {
    loadDigitalArtwork({
      variables: { limit: rowsPerPage, offset: offset, search: `%${search}%` },
      fetchPolicy: "network-only",
    });
  }, [loadDigitalArtwork, rowsPerPage, offset, search]);

  useEffect(() => {
    if (resultData.data) {
      setData(resultData.data.digital_art_work);
      setCount(resultData?.data.digital_art_work_aggregate.aggregate.count);
    }
  }, [resultData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchValue);
    if (searchValue === "") {
      loadDigitalArtwork(
        {
          variables: {
            limit: rowsPerPage,
            offset: offset,
            search: `%${search}%`,
          },
          fetchPolicy: "network-only",
        },
        [loadDigitalArtwork, rowsPerPage, offset, search]
      );
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  if (!data) {
    return "no data";
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        {/* dashboard */}
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard" className="dashboard">
              Dashboard
            </Link>
            <span>ArtWork</span>
          </Breadcrumbs>
        </div>
        {/* search */}
        <div>
          <form onSubmit={handleSearch}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
              }}
            >
              {/* Search Box */}

              <InputBase
                id="search-by-phone"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search By Name or Phone"
                type="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                //   color="warning"
                sx={{ p: "10px" }}
                aria-label="directions"
                type="submit"
                value={search}
                onClick={handleSearch}
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </form>
        </div>
        {/* add */}
        <Button
          variant="contained"
          sx={{
            px: 3,
            py: 1,
          }}
          color="secondary"
          onClick={() => navigate("/create_digital_artwork")}
        >
          Add
        </Button>
      </div>

      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            // maxHeight: "70vh",
            Width: "100px",
            border: "1px groove rgba(0,0,0,0.2)",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table , responsive table"
            className={classes.table}
          >
            <TableHead>
              <StyledTableRow>
                <TableCell
                  style={{
                    minWidth: 100,
                    fontWeight: "bold",
                  }}
                >
                  ID
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Artwork Image
                </TableCell>
                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Artwork Name
                </TableCell>

                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Artwork Year
                </TableCell>

                <TableCell style={{ minWidth: 70, fontWeight: "bold" }}>
                  Status
                </TableCell>
                <TableCell style={{ minWidth: 100, fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </StyledTableRow>
            </TableHead>

            {data.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Digital Artworks Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <StyledTableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell>{index + 1}</TableCell>

                      <TableCell>
                        <Avatar
                          width="52px"
                          height="52px"
                          src={row.artwork_image_url}
                        ></Avatar>
                      </TableCell>
                      <TableCell>{row.artwork_name}</TableCell>
                      <TableCell>{row.artwork_year}</TableCell>

                      <TableCell>
                        {row.pending ? (
                          <Typography
                            sx={{
                              backgroundColor: "orange",
                              px: 2,
                              py: 1,
                              color: "#fff",
                              borderRadius: "3px",
                            }}
                          >
                            Pending
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              backgroundColor: "green",
                              px: 2,
                              py: 1,
                              color: "#fff",
                              borderRadius: "3px",
                            }}
                          >
                            Approve
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          sx={{ color: "white", p: 1, mr: 1 }}
                          onClick={() => navigate(`/digital_artwork/${row.id}`)}
                        >
                          Detail
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ color: "black" }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
};

export default DigitalArtworks;
