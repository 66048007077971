import { useMutation } from "@apollo/client";
import {
  CardContent,
  FormControl,
  Typography,
  Button,
  Card,
  Breadcrumbs,
  Box,
  FormLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { ADD_VIDEO_CAT, ALL_VIDEO_CATS } from "../../gql/videoCategory";
import { ADD_ARTICLE_CAT, ALL_ARTICLE_CATS } from "../../gql/articleCategory";

const CreateVideoCateory = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isTraditional, setIsTraditional] = useState(true);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [addArticleCat] = useMutation(ADD_ARTICLE_CAT, {
    onError: (err) => {
      alert("Error on Server");
    },
    onCompleted: (result) => {
      setValues({});

      alert("New Article Category has been added");
      navigate("/article_cat");
    },
    refetchQueries: [ALL_ARTICLE_CATS],
  });

  const handleCreate = async (e) => {
    let isErrorExit = false;
    let errorObject = {};
    setLoading(true);
    if (!values.name) {
      isErrorExit = true;
      errorObject.name = "Name is required";
    }

    if (isErrorExit) {
      setErrors(errorObject);

      return;
    }
    try {
    } catch (error) {
      console.log("Error ", error);
    }
    await addArticleCat({
      variables: { ...values, is_traditional: isTraditional },
    });
  };

  return (
    <>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          {/* dashboard */}
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              {/* <Link to="/" className="dashboard"> */}
              <Typography variant="h6">
                Mula Dashboard (Article Category)
              </Typography>

              {/* </Link> */}
              {/* <span>ArtWork</span> */}
            </Breadcrumbs>
            <Typography>Main / Article Category</Typography>
          </div>
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </div>
        <CardContent sx={{ p: "2rem" }}>
          <Box sx={{ my: "1rem" }}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Choose Traditional or Digital
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isTraditional}
                onChange={(e) => setIsTraditional(e.target.value)}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Traditional"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Digital"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "grid",
              rowGap: "2rem",
            }}
          >
            {/* name  */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Category Name</FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="cat_name"
                placeholder="Enter Category Name"
                value={values.name}
                onChange={handleChange("name")}
                error={errors.name ? true : false}
                helperText={errors.name}
              />
            </FormControl>
            {/* name mm  */}
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>
                Category Name MM
              </FormLabel>
              <TextField
                variant="outlined"
                InputProps={{ sx: { height: 50 } }}
                id="cat_name"
                placeholder="Enter Category Name MM"
                value={values.name_mm}
                onChange={handleChange("name_mm")}
                error={errors.name_mm ? true : false}
                helperText={errors.name_mm}
              />
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-end" my="2rem">
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleCreate}
            >
              Create
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
export default CreateVideoCateory;
