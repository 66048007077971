import { gql } from "@apollo/client";

//get all videos
export const ALL_VIDEOS = gql`
  query MyQuery($limit: Int!, $offset: Int!, $videoQuery: videos_bool_exp!) {
    videos(
      order_by: { updated_at: desc }
      limit: $limit
      offset: $offset
      where: $videoQuery
    ) {
      created_at
      description_mm
      description_en
      duration_minute
      id
      pending
      thumbnail_image_url
      updated_at
      video_name
      video_name_mm
      video_url
      is_traditional
      video_category {
        id
        name
      }

      videos_creator {
        name
        id
      }
    }
    videos_aggregate(where: $videoQuery) {
      aggregate {
        count
      }
    }
  }
`;

//get one video
export const ONE_VIDEO = gql`
  query MyQuery($id: Int!) {
    videos_by_pk(id: $id) {
      created_at
      description_en
      description_mm
      duration_minute
      id
      pending
      thumbnail_image_url
      updated_at
      video_name
      video_name_mm
      is_traditional
      video_url
      fk_video_category_id
      fk_video_creator_id
      video_category {
        id
        name
      }

      videos_creator {
        name
        id
      }
    }
  }
`;

//delete video
export const DELETE_VIDEO = gql`
  mutation MyMutation($id: Int!) {
    delete_videos_by_pk(id: $id) {
      id
    }
  }
`;

//cteate video upload
export const CREATE_VIDEO_UPLOAD = gql`
  mutation MyMutation(
    $description_mm: String!
    $description_en: String!
    $duration_minute: Int!
    $thumbnail_image_url: String!
    $video_name: String!
    $video_name_mm: String!
    $video_url: String!
    $fk_video_category_id: Int!
    $fk_video_creator_id: Int!
    $is_traditional: Boolean!
  ) {
    insert_videos_one(
      object: {
        description_mm: $description_mm
        description_en: $description_en
        duration_minute: $duration_minute
        thumbnail_image_url: $thumbnail_image_url
        video_name: $video_name
        video_name_mm: $video_name_mm
        video_url: $video_url
        is_traditional: $is_traditional
        fk_video_category_id: $fk_video_category_id
        fk_video_creator_id: $fk_video_creator_id
      }
    ) {
      created_at
      description_mm
      description_en
      duration_minute
      id
      pending
      thumbnail_image_url
      updated_at
      video_name
      video_url
    }
  }
`;

//update video
export const UPDATE_VIDEO_UPLOAD = gql`
  mutation MyMutation(
    $id: Int!
    $description_mm: String!
    $description_en: String!
    $duration_minute: Int!
    $thumbnail_image_url: String!
    $video_name: String!
    $video_name_mm: String!
    $video_url: String!
    $fk_video_category_id: Int!
    $fk_video_creator_id: Int!
    $is_traditional: Boolean!
  ) {
    update_videos_by_pk(
      pk_columns: { id: $id }
      _set: {
        description_mm: $description_mm
        description_en: $description_en
        is_traditional: $is_traditional
        duration_minute: $duration_minute
        thumbnail_image_url: $thumbnail_image_url
        video_name: $video_name
        video_name_mm: $video_name_mm
        video_url: $video_url
        fk_video_category_id: $fk_video_category_id
        fk_video_creator_id: $fk_video_creator_id
      }
    ) {
      created_at
      description_mm
      description_en
      duration_minute
      id
      pending
      thumbnail_image_url
      updated_at
      video_name
      video_url
    }
  }
`;

//disable or Enable Artwork
export const PENDING_STATUS = gql`
  mutation MyMutation($id: Int!, $pending: Boolean!) {
    update_videos_by_pk(pk_columns: { id: $id }, _set: { pending: $pending }) {
      pending
      id
    }
  }
`;

//for video category
export const VIDEO_CAT = gql`
  query aa($is_traditional: Boolean!) {
    video_category(where: { is_traditional: { _eq: $is_traditional } }) {
      id
      name
    }
  }
`;

//video creator
export const VIDEO_CREATOR = gql`
  query aa($is_traditional: Boolean!) {
    video_creator(where: { is_traditional: { _eq: $is_traditional } }) {
      id
      name
    }
  }
`;
