import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import EventAvailableSharpIcon from "@mui/icons-material/EventAvailableSharp";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import BrushIcon from "@mui/icons-material/Brush";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PaletteIcon from "@mui/icons-material/Palette";

import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import ArticleIcon from "@mui/icons-material/Article";
import Face4SharpIcon from "@mui/icons-material/Face4Sharp";
import SellIcon from "@mui/icons-material/Sell";
import CategoryIcon from "@mui/icons-material/Category";
import { Box } from "@mui/material";
import icons from "../view/icons";
import { Link } from "react-router-dom";
import WebStoriesSharpIcon from "@mui/icons-material/WebStoriesSharp";

import "../style/App.css";
import SideBarContext from "../context/SideBarContext";
import { FeedbackSharp } from "@mui/icons-material";
const drawerWidth = 230;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBar = ({ open }) => {
  const { nav, setNav } = useContext(SideBarContext);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#f7f7f7",
        },
      }}
      className="sidebar"
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "20px",
            margin: "auto",
            mt: 1,
            mb: 1.5,
          }}
        >
          <img src={icons.logo} alt="mula" width="50px" height="50px" />
        </Box>
      </DrawerHeader>
      <Divider />
      <List className="nav-list">
        {/* <Link to="/dashboard" onClick={() => setNav("")} className="nav-link">
          <ListItem
            button
            className={`${nav === "" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <DashboardIcon className="nav-link-icon" />
            </ListItemIcon>
            Dashboard
          </ListItem>
        </Link> */}
        {/* user */}
        <Link to="/user" onClick={() => setNav("user")} className="nav-link">
          <ListItem
            button
            className={`${nav === "user" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <AccountCircleSharpIcon className="nav-link-icon" />
            </ListItemIcon>
            Users
          </ListItem>
        </Link>
        {/* feedback */}
        <Link
          to="/feedBack"
          onClick={() => setNav("feedBack")}
          className="nav-link"
        >
          <ListItem
            button
            className={`${nav === "feedBack" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <FeedbackSharp className="nav-link-icon" />
            </ListItemIcon>
            Feed Back
          </ListItem>
        </Link>
        <Divider sx={{ my: "1rem" }} />
        {/* artwork */}
        <Link
          to="/art_work"
          onClick={() => setNav("art_work")}
          className="nav-link"
        >
          {/* <ListItem button className={`nav-btn ${nav === "" && "active"}`}> */}
          <ListItem
            button
            className={`${nav === "art_work" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <PaletteIcon className="nav-link-icon" />
            </ListItemIcon>
            ArtWork
          </ListItem>
        </Link>
        {/* Series */}
        <Link to="/serie" onClick={() => setNav("serie")} className="nav-link">
          <ListItem
            button
            className={`${nav === "serie" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <WebStoriesSharpIcon className="nav-link-icon" />
            </ListItemIcon>
            Series
          </ListItem>
        </Link>
        <Divider sx={{ my: "1rem" }} />
        {/* artist */}
        <Link
          to="/artist"
          onClick={() => setNav("artist")}
          className="nav-link"
        >
          <ListItem
            button
            className={`${nav === "artist" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <BrushIcon className="nav-link-icon" />
            </ListItemIcon>
            Artist
          </ListItem>
        </Link>
        {/* reseller */}
        <Link
          to="/reseller"
          onClick={() => setNav("reseller")}
          className="nav-link"
        >
          <ListItem
            button
            className={`${nav === "reseller" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <SellIcon className="nav-link-icon" />
            </ListItemIcon>
            Reseller
          </ListItem>
        </Link>
        <Divider sx={{ my: "1rem" }} />
        {/* video creator */}
        <Link
          to="/video_creator"
          onClick={() => setNav("video_creator")}
          className="nav-link"
        >
          <ListItem
            button
            className={`${
              nav === "video_creator" ? "nav-btn active" : "nav-btn"
            }`}
          >
            <ListItemIcon>
              <Face4SharpIcon className="nav-link-icon" />
            </ListItemIcon>
            Video Creator
          </ListItem>
        </Link>
        {/* video category */}
        <Link
          to="/video_cat"
          onClick={() => setNav("video_cat")}
          className="nav-link"
        >
          <ListItem
            button
            className={`${nav === "video_cat" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <CategoryIcon className="nav-link-icon" />
            </ListItemIcon>
            Video Category
          </ListItem>
        </Link>
        {/* Video Upload */}
        <Link to="/video" onClick={() => setNav("video")} className="nav-link">
          <ListItem
            button
            className={`${nav === "video" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <CloudUploadIcon className="nav-link-icon" />
            </ListItemIcon>
            Video Upload
          </ListItem>
        </Link>
        <Divider sx={{ my: "1rem" }} />
        {/* article category */}
        <Link
          to="/article_cat"
          onClick={() => setNav("article_cat")}
          className="nav-link"
        >
          <ListItem
            button
            className={`${
              nav === "article_cat" ? "nav-btn active" : "nav-btn"
            }`}
          >
            <ListItemIcon>
              <CategoryIcon className="nav-link-icon" />
            </ListItemIcon>
            Article Category
          </ListItem>
        </Link>
        {/* Article */}
        <Link
          to="/article"
          onClick={() => setNav("article")}
          className="nav-link"
        >
          <ListItem
            button
            className={`${nav === "article" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <ArticleIcon className="nav-link-icon" />
            </ListItemIcon>
            Article
          </ListItem>
        </Link>
        <Divider sx={{ my: "1rem" }} />
        {/* Event */}
        <Link to="/event" onClick={() => setNav("event")} className="nav-link">
          <ListItem
            button
            className={`${nav === "event" ? "nav-btn active" : "nav-btn"}`}
          >
            <ListItemIcon>
              <EventAvailableSharpIcon className="nav-link-icon" />
            </ListItemIcon>
            Event
          </ListItem>
        </Link>

        <Divider sx={{ my: "1.5rem" }} />
        {/* Digital artwork */}
        <Link
          to="/digital_artwork"
          onClick={() => setNav("digital_artwork")}
          className="nav-link"
        >
          <ListItem
            button
            className={`${
              nav === "digital_artwork" ? "nav-btn active" : "nav-btn"
            }`}
          >
            <ListItemIcon>
              <PaletteIcon className="nav-link-icon" />
            </ListItemIcon>
            Digital Artwork
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
};

export default SideBar;
