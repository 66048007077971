import { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Modal,
  Breadcrumbs,
  CardContent,
  CardMedia,
  Card,
  CardActions,
  ListItem,
  ListItemText,
} from "@mui/material";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import {
  ALL_VIDEOS,
  DELETE_VIDEO,
  ONE_VIDEO,
  PENDING_STATUS,
  UPDATE_VIDEO_UPLOAD,
} from "../../gql/videoUpload";
const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  borderRadius: 1,
  p: 4,
};

const Video = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoPreview, setVideoPreview] = useState();
  const { data } = useQuery(ONE_VIDEO, { variables: { id: id } });

  const [delete_video] = useMutation(DELETE_VIDEO, {
    onError: (error) => {
      alert("delete error");
    },
    onCompleted: (data) => {
      alert("video had been deleted");
    },
    refetchQueries: [ALL_VIDEOS],
  });

  const [pending_status] = useMutation(PENDING_STATUS, {
    onError: (err) => {
      alert("Pending Error");
      setLoading(false);
    },
  });
  const [edit_video] = useMutation(UPDATE_VIDEO_UPLOAD, {
    onError: (err) => {
      setLoading(false);
      console.log("error", err);
      alert("Update Enable or Disabled Error");
    },
  });

  // const [deleteImage] = useMutation(DELETE_IMAGE, {
  //   onError: (error) => {
  //     console.log("error : ", error);
  //   },
  //   onCompleted: () => {
  //     navigate("/artist");
  //   },
  // });

  const handleRemove = async () => {
    // let image_url = data.videos_by_pk.artist_profile_image_url;
    // console.log("image url", image_url);
    // let image_name = image_url.substring(
    //   image_url.lastIndexOf("/") + 1,
    //   image_url.lenght
    // );
    // await deleteImage({ variables: { image_name: image_name } });
    await delete_video({ variables: { id: id } });
    navigate(-1);
  };

  const handleCloseR = () => setOpen(false);
  const handleRemoveOpen = (row) => {
    setOpen(true);
  };

  if (!data) {
    return "no data";
  }

  return (
    <>
      <div role="presentation" className="align">
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className="dashboard">
            Dashboard
          </Link>
          <Link to="/video" className="dashboard">
            Video
          </Link>
          <span>{id}</span>
        </Breadcrumbs>
      </div>
      <Typography variant="h6" component="h2" sx={{ m: 3, color: "black" }}>
        Video Details
      </Typography>

      <Card>
        <CardContent>
          <Box className="photoColumn">
            <CardMedia
              component="img"
              height="300px"
              image={data.videos_by_pk.thumbnail_image_url}
            />

            <Box>
              <video controls width="300" height="300">
                <source src={data?.videos_by_pk?.video_url} type="video/mp4" />
              </video>
            </Box>
          </Box>

          <CardActions
            sx={{
              display: "grid",
              mt: "2rem",
              gridTemplateColumns: "repeat(5, 1fr)",
              gap: "1rem",
            }}
          >
            <ListItem>
              <ListItemText
                primary="ID"
                secondary={data.videos_by_pk.id}
              ></ListItemText>
            </ListItem>
            {/* video category */}
            <ListItem>
              <ListItemText
                primary="Video Category"
                secondary={data.videos_by_pk?.video_category?.name}
              ></ListItemText>
            </ListItem>
            {/* video creator*/}
            <ListItem>
              <ListItemText
                primary="Video Creator"
                secondary={data.videos_by_pk?.videos_creator?.name}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Video Name"
                secondary={data.videos_by_pk.video_name}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Video Name MM"
                secondary={data.videos_by_pk?.video_name_mm}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Duration In Minute"
                secondary={data.videos_by_pk?.duration_minute}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Traditional or Digital"
                secondary={
                  data.videos_by_pk?.is_traditional === true
                    ? "Traditional"
                    : "Digital"
                }
              ></ListItemText>
            </ListItem>
          </CardActions>
          <Box display="grid" columnGap="1rem" mx="2rem">
            <Box>
              <Typography display="flex" justifyContent="center" mt="2rem">
                Description Eng
              </Typography>

              <Box sx={{ mt: "1rem", bgcolor: "#f8f9fa" }}>
                <div
                  style={{ color: "#495057", padding: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: data.videos_by_pk.description_en,
                  }}
                ></div>
              </Box>
            </Box>
            <Box>
              <Typography display="flex" justifyContent="center" mt="2rem">
                Description MM
              </Typography>

              <Box sx={{ mt: "1rem", bgcolor: "#f8f9fa" }}>
                <div
                  style={{ color: "#495057", padding: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: data.videos_by_pk.description_mm,
                  }}
                ></div>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Box display="flex" justifyContent="space-between" m="2rem">
          <Box>
            {data.videos_by_pk.pending ? (
              <Button
                variant="contained"
                color="warning"
                onClick={() =>
                  pending_status({
                    variables: {
                      id: data.videos_by_pk.id,
                      pending: false,
                    },
                  })
                }
              >
                Approve
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  pending_status({
                    variables: {
                      id: data.videos_by_pk.id,
                      pending: true,
                    },
                  })
                }
              >
                Pending
              </Button>
            )}
          </Box>
          <Box display="flex" justifyContent="end" columnGap="1rem">
            <Button
              sx={{ mr: "2rem" }}
              variant="contained"
              onClick={() => navigate(`/update_video/${data.videos_by_pk.id}`)}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpen(true)}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Card>
      <Modal
        keepMounted
        open={open}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button loading={loading} onClick={handleRemove}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default Video;
