import { gql } from "@apollo/client";
//get all feedbacks
export const ALL_FEEDBACK = gql`
  query aa($limit: Int!, $offset: Int!) {
    feedback(order_by: { updated_at: desc }, limit: $limit, offset: $offset) {
      message
      subject
      feedback_user {
        fullname
      }
    }
    feedback_aggregate {
      aggregate {
        count
      }
    }
  }
`;

//get by feedback by pk
export const FEEFBACK_BY_PK = gql`
  query ss($id: Int!) {
    feedback_by_pk(id: $id) {
      subject
      message
      feedback_user {
        fullname
      }
    }
  }
`;

//delete feedback
export const DELETE_FEEDBACK = gql`
  mutation aa($id: Int!) {
    delete_feedback_by_pk(id: $id) {
      id
    }
  }
`;
